import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { Context } from "../../context/store";

const PrivateRoute = ({ children }) => {
    const { state } = useContext(Context);
    const { is_verified } = state.user_data;

    return is_verified ? children : <Navigate to="/tefun?action=login" />;
};

export default PrivateRoute;
