import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import CountrySelector from "../CountrySelector";

import {
    Link,
    Navigate,
    useNavigate,
    useLocation,
    useSearchParams,
} from "react-router-dom";
import FlagDropDown from "../FlagDropDown";
import { Context } from "../../components/context/store";
import axios from "axios";
import { accountsConfig } from "../../components/helpers/axiosConfig";
// import ButtonLoader from "../loaders/ButtonLoader";
import TefunLoader from "../../components/tefun/TefunLoader";

const LoginPage = () => {
    const { state, dispatch } = useContext(Context);
    const country = state.country_details.country;
    const [selectedCountry, setSelectedCountry] = useState("");
    const [countryselector, setCountryselector] = useState(false);
    const [error, setError] = useState(false);
    const [phone, setPhone] = useState("");
    const [error_message, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [dataPhone, setDataPhone] = useState({});
    const navigate = useNavigate();
    const ismodal = state.user_validate.ismodal;

    const handleShow = () => {
        setCountryselector((prevValue) => !prevValue);
    };
    const onChange = (e) => {
        setError(false);
        const re = /^[0-9\b]+$/;
        if (e.target.value === "" || re.test(e.target.value)) {
            setPhone(e.target.value);
        }
    };
    const [searchParams, setSearchParams] = useSearchParams();
    const onSelectHandler = (selected) => {
        setSelectedCountry(selected);
        // props.updateUserData({ selectedCountry: selected });
    };
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            // verifyService();
        } else if (e.keyCode === 69) {
            e.preventDefault();
        }
    };
    const handleEnter = () => {
        setIsLoading(true);
        accountsConfig
            .post("/api/v1/accounts/login/enter/", {
                country: country.web_code,
                phone: phone,
            })
            .then(function (response) {
                setIsLoading(false);
                setDataPhone(response.data);
                if (response.data.StatusCode === 6000) {
                    dispatch({
                        type: "USER_CHECK_VALIDATE",
                        payload: {
                            ...state.user_validate,
                            number: phone,
                            status: response.data.StatusCode,
                            country: country.web_code,
                            // action:'enterpassword'
                        },
                    });
                    setSearchParams({ action: "password" });

                    // navigate('otp',{state:{id:1,name:'wazeer'}})
                } else if (response.data.StatusCode === 6001) {
                    setIsLoading(false);
                    dispatch({
                        type: "USER_CHECK_VALIDATE",
                        payload: {
                            ...state.user_validate,
                            number: phone,
                            status: response.data.StatusCode,
                            country: country.web_code,
                            islogin: true,
                            // action:'sighnup'
                        },
                    });
                    setSearchParams({ action: "phone" });

                    // navigate('otp/password',{state:{id:1,name:'wazeer'}})
                }
            });
    };

    return (
        <Container>
            <CountrySelector
                show={show}
                setShow={setShow}
                selectedCountry={selectedCountry}
                onSelectHandler={onSelectHandler}
                setCountryselector={setCountryselector}
                countryselector={countryselector}
                handleShow={handleShow}
            />
            <Content>
                <Shape
                    src={
                        "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/auth/shape.svg"
                    }
                    alt=""
                />
                <Title className="g-medium">Login to your account</Title>
                <Description className="g-medium">
                    Enter your registered phone number
                </Description>
                <MiddleContainer>
                    <>
                        {" "}
                        <div onClick={() => setShow(!show)}>
                            <FlagDropDown
                                selectedCountry={setSelectedCountry}
                                // handleShow={handleShow}
                                setCountryselector={setCountryselector}
                                // countryselector={countryselector}
                            />
                        </div>
                        <InputContainer
                            className="g-medium"
                            style={{
                                borderColor: error ? "#f32e2f" : "#2f3337",
                            }}
                        >
                            <Icon
                                src={
                                    "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/auth/phone.svg"
                                }
                                alt=""
                                onClick={() => setError(!error)}
                            />
                            {country.phone_code}
                            {selectedCountry && selectedCountry.phone_code}
                            <InputField
                                autoFocus
                                className="g-medium"
                                placeholder="Enter your phone number"
                                onChange={onChange}
                                value={phone}
                                onKeyDown={handleKeyDown}
                            />
                            {error && (
                                <ErrorText className="g-medium">
                                    {error_message}
                                </ErrorText>
                            )}
                        </InputContainer>
                    </>
                </MiddleContainer>
            </Content>

            <BottomButton className="g-medium" onClick={(e) => handleEnter(e)}>
                {isLoading ? <TefunLoader /> : "Next"}
            </BottomButton>
            <BottomRow className="g-medium">
                New to yia.ai?
                <RowItem
                    className="g-medium"
                    onClick={() => {
                        dispatch({
                            type: "USER_CHECK_VALIDATE",
                            payload: {
                                ...state.user_validate,
                                action: "sighnup",
                            },
                        });
                        setSearchParams({ action: "phone" });
                    }}
                >
                    Create an account
                </RowItem>
            </BottomRow>
        </Container>
    );
};

export default LoginPage;

const Container = styled.div`
    background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/auth/decorator.svg");
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    width: 100%;
    padding: 169px 53px 0 53px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100vh;

    @media (max-width: 1100px) {
        /* width: 64%; */
    }
    @media (max-width: 980px) {
        width: 100%;
        padding: 169px 49px 0;
    }
    @media (max-width: 640px) {
        padding: 169px 30px 0;
    }
    @media (max-width: 480px) {
        padding: 91px 25px 0;
        /* background-position-y: -37px; */
    }
    @media (max-width: 420px) {
        padding: 91px 12px 0;
    }
    @media (max-width: 360px) {
        padding: 91px 10px 0;
    }
`;
const Title = styled.h4`
    font-size: 25px;
    line-height: 1.4em;
    margin-top: 14px;
    @media (max-width: 640px) {
        font-size: 23px;
        margin-top: 38px;
    }
    @media (max-width: 480px) {
        margin-top: 25px;
        font-size: 20px;
    }
`;
const Description = styled.p`
    margin: 18px 0 40px;
    width: 94%;
    font-size: 14px;
    @media (max-width: 480px) {
        font-family: unset;
        width: 100%;
        font-size: 13px;
        margin-top: 7px;
    }
`;
const Content = styled.div``;
const InputContainer = styled.div`
    position: relative;
    border: 1px solid;
    border-radius: 7px;
    padding: 15px 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    font-size: 15px;
    margin-left: 10px;
    width: -webkit-fill-available;
    width: -moz-available;
    @media all and(max-width: 640px) {
        padding: 13px 13px;
    }
    @media all and(max-width: 480px) {
        padding: 8px 8px;
        border-color: #5f6367;
        font-size: 13px;
    }
    @media (max-width: 420px) {
        padding: 12px 8px;
        font-size: 14px;
    }
    @media (max-width: 360px) {
        font-size: 13px;
    }
    &:focus-within {
        border-color: #5cc66a;
    }
`;
const Icon = styled.img`
    max-width: 17px;
    max-height: 17px;
    margin-right: 12px;
    display: block;

    @media (max-width: 360px) {
        margin-right: 6px;
    }
`;
const InputField = styled.input`
    width: 81%;
    color: #000;
    font-size: 15px;
    padding-left: 8px;
    caret-color: #5cc66a;
    @media all and(max-width: 640px) {
        width: 81%;
    }
    @media all and(max-width: 480px) {
        width: 80%;
        padding-left: 8px;
        font-size: 14px;
        transform: scale(0.9375);
        transform-origin: left top;
    }
    @media (max-width: 420px) {
        font-size: 14px;
    }
    @media (max-width: 360px) {
        font-size: 13px;
    }
`;
const BottomRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 14px 0 70px;
    font-size: 14px;
    @media (max-width: 640px) {
        margin: 20px 0 70px;
    }
    @media (max-width: 480px) {
        margin: 20px 0 40px;
        font-size: 13px;
    }
`;
const RowItem = styled.div`
    color: #5cc66a;
    font-size: 14px;
    margin-left: 7px;
    cursor: pointer;
    @media (max-width: 480px) {
        font-size: 13px;
    }
`;
const LoaderContainer = styled.div`
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;
const MiddleContainer = styled.div`
    display: flex;
    position: relative;
    @media (max-width: 420px) {
        align-items: center;
    }
`;
const ErrorText = styled.span`
    font-size: 13px;
    position: absolute;
    left: 0;
    color: #f46565;
    bottom: -27px;
    @media (max-width: 480px) {
        font-size: 12px;
        bottom: -26px;
    }
`;
const BottomButton = styled.div`
    background: linear-gradient(
        272deg,
        rgba(34, 193, 195, 1) 0%,
        rgba(99, 187, 76, 1) 0%,
        rgba(24, 152, 175, 1) 100%
    );
    cursor: pointer;
    display: block;
    border-radius: 6px;
    /* height: 58px; */
    padding: 15px 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    font-size: 14px;
    color: #fff;
    @media (max-width: 480px) {
        height: 44px;
        font-size: 13px;
    }
    @media (max-width: 420px) {
        padding: 12px 8px;
    }
`;
const Shape = styled.img`
    max-height: 29px;
    max-width: 29px;
    @media (max-width: 640px) {
        display: none;
    }
`;
