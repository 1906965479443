// First we need to import axios.js
import axios from "axios";

//#### LIVE SERVER CONFIGS #### //
export const accountsConfig = axios.create({
    baseURL: "https://api.yia.ai",
});
export const yiaaiConfig = axios.create({
    baseURL: "https://api.yia.ai/api/v1/",
});
export const communityConfig = axios.create({
    baseURL: "https://community.talrop.com/api/v1/",
});

// #### DEVELOPER SERVER CONFIGS #### //
// export const accountsConfig = axios.create({
//     baseURL: "https://developers-yiaai.talrop.com",
// });
// export const yiaaiConfig = axios.create({
//     baseURL: "https://developers-yiaai.talrop.com/api/v1/",
// });
// export const talropAccounts = axios.create({
//     baseURL: "https://developers-accounts.talrop.com/",
// });
// export const communityConfig = axios.create({
//     baseURL: "https://developers-community.talrop.com/api/v1/",
// });

// #### LOCAL IP CONFIGS #### //

// export const accountsConfig = axios.create({
//     baseURL: "http://192.168.46.198:8000/",
// });
// export const yiaaiConfig = axios.create({
//     baseURL: "http://192.168.46.198:8000/api/v1/",
// });
