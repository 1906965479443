import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
// import { useContext } from "react/cjs/react.production.min";
import { Context } from "../components/context/store";
import axios from "axios";

function CountrySelector({
    show,
    selectedCounrty,
    onSelectHandler,
    handleShow,
    setCountryselector,
    countryselector,
    setShow,
}) {
    const [countryDetails, setCountryDetails] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const { state, dispatch } = useContext(Context);
    // const [selectedCounrty, setSelectedCountry] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    // console.log(state);
    // const {state ,dispatch}=useContext(Context)
    useEffect(() => {
        const fetchCountries = () => {
            axios
                .get(
                    "https://developers-accounts.talrop.com/api/v1/users/settings/countries/"
                )
                .then((response) => {
                    let { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        setCountryDetails(data);
                    }
                });
        };
        fetchCountries();
    }, []);

    const country_code = state.user_data.country_code;
    const handleChange = (e) => {
        e.preventDefault();
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        const results = countryDetails.filter(
            (item) =>
                item.name.includes(searchTerm) ||
                item.name.toLowerCase().includes(searchTerm) ||
                item.name.toUpperCase().includes(searchTerm)
        );
        setSearchResults(results);
    }, [searchTerm, countryDetails]);

    const renderCountries = searchResults.map((item, index) => (
        <DataEach
            onClick={() => {
                // handleClick();
                // onSelectHandler(item);
                // setSearchTerm("");
                dispatch({
                    type: "COUNTRY_SELECTED",
                    payload: {
                        ...state.country_details,
                        country: item,
                    },
                });
                setShow((prev) => !prev);
                // handleClick()
            }}
        >
            <Left>
                <img src={item.flag} alt="img" />
            </Left>
            <Right>
                <h5>{item.name}</h5>
            </Right>
        </DataEach>
    ));

    return show ? (
        <Cover>
            <Box>
                <Top>
                    <div>
                        <input
                            type="text"
                            placeholder="Select Country"
                            onChange={handleChange}
                        />
                    </div>
                </Top>
                <Widget>
                    {searchResults.length === 0
                        ? countryDetails.map((item) => (
                              <DataEach
                                  onClick={() => {
                                      onSelectHandler(item);
                                      setSearchTerm("");
                                      //   console.log(item.web_code);
                                  }}
                              >
                                  <Left>
                                      <img src={item.flag} alt="img" />
                                  </Left>
                                  <Right>
                                      <h5>{item.name}</h5>
                                  </Right>
                              </DataEach>
                          ))
                        : searchResults.length != 0
                        ? renderCountries
                        : "No results"}
                </Widget>
            </Box>
        </Cover>
    ) : null;
}

export default CountrySelector;
const Cover = styled.div`
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
`;
const Box = styled.div`
    width: 300px;
    height: 380px;
    padding: 10px;
    border-radius: 8px;
    background: #fff;
`;
const Top = styled.div`
    background: linear-gradient(
        272deg,
        rgba(34, 193, 195, 1) 0%,
        rgba(99, 187, 76, 1) 0%,
        rgba(24, 152, 175, 1) 100%
    );
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    border-radius: 8px;
    margin-bottom: 10px;
    div {
        background: #fff;
        width: 100%;
        padding: 5px;
        /* border: 1px solid #000; */
        border-radius: 8px;
    }
    input {
        width: 100%;
        height: 30px;
        font-family: "gordita_medium";
        padding-left: 10px;
    }
`;
const Widget = styled.div`
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
    max-width: 400px;
    height: 300px;
    padding: 0 10px;
    text-align: center;
`;
const DataEach = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    :hover {
        background: linear-gradient(
            272deg,
            rgba(34, 193, 195, 1) 0%,
            rgba(99, 187, 76, 1) 0%,
            rgba(24, 152, 175, 1) 100%
        );
        border-radius: 8px;
    }
`;
const Left = styled.div`
    margin-right: 10px;
    img {
        width: 37px;
        height: 37px;
        display: block;
        border-radius: 50%;
    }
`;
const Right = styled.div`
    font-family: "gordita_medium";
`;
