import React from "react";
import styled from 'styled-components';

function Timer({ onResend, isResendLoading, isResendSuccess,counter, setCounter }){



    return (
        <>
            {counter === 0 ? (
                <TimerContainer onClick={onResend}>
                    {isResendSuccess ? (
                        <SendIcon
                            className="las la-check-circle"
                            style={{
                                color: isResendSuccess
                                    ? "#5cc66a"
                                    : isResendSuccess === false
                                    ? "#f44336"
                                    : "#000",
                            }}
                        ></SendIcon>
                    ) : isResendSuccess === false ? (
                        <SendIcon
                            style={{ color: "#f44336" }}
                            className="las la-exclamation-circle"
                        ></SendIcon>
                    ) : (
                        <SendIcon
                            className={`las la-undo-alt ${isResendLoading && "loader"}`}
                        ></SendIcon>
                    )}

                    <Resend
                        className="b-medium"
                        style={{
                            color: isResendSuccess
                                ? "#5cc66a"
                                : isResendSuccess === false
                                ? "#f44336"
                                : "#000",
                        }}
                    >
                        {isResendSuccess
                            ? "OTP Sent!"
                            : isResendSuccess === false
                            ? "Resend OTP failed"
                            : "Resend OTP"}
                    </Resend>
                </TimerContainer>
            ) : (
                <>
                    <Resend className="b-medium resend">Resend otp in <small>{counter}</small>s</Resend>
                </>
            )}
        </>
    );
};

export default Timer;

const TimerContainer = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const SendIcon = styled.span`
    font-size: 14px;
    -webkit-text-stroke-width: 0.02em;
    &.loader {
        animation: rotate 2s infinite linear reverse;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;
const Resend = styled.span`
    font-size: 14px;
    margin-left: 5px;
    & small{
        font-size: 14px;
        color: #56B55D;
    }
`;