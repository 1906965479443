import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import TefunLoader from "../../components/tefun/TefunLoader";
import {
    Link,
    useHistory,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import OtpCard from "./OtpCard";
import { Context } from "../../components/context/store";
import axios from "axios";
import { yiaaiConfig } from "../../components/helpers/axiosConfig";

const LoginWithOtp = (props) => {
    const [otpResponse, setOtpResponse] = useState([]);
    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [isResendLoading, setResendLoading] = useState(false);
    const [isResendSuccess, setResendSuccess] = useState(null);
    const [otp, setOtp] = useState("");
    const [nextPath, setNextPath] = useState("");
    const { state, dispatch } = useContext(Context);
    const number = state.user_validate.number;
    const country = state.country_details.country;
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {}, []);
    const handleOtp = (e) => {
        setLoading(true);
        e.preventDefault();
        yiaaiConfig
            .post("accounts/signup/verify/phone/", {
                country: country.web_code,
                phone: number,
                otp: otp,
            })
            .then(function (response) {
                setLoading(false);
                if (response.data.StatusCode === 6000) {
                    dispatch({
                        type: "USER_CHECK_VALIDATE",
                        payload: {
                            ...state.user_validate,
                            action: "name",
                        },
                    });
                    setSearchParams({ action: "name" });
                } else {
                    setLoading(false);
                    setError(true);
                    setErrorMessage(response.data.message);
                }
            });
    };

    return (
        <Container>
            <Content>
                <Title className="g-medium">
                    A One Time Password has been sent!
                </Title>
                <Description className="g-medium">
                    Please enter the four-digit number which has been sent to
                    the registered mobile number.
                </Description>

                {/* <OtpCard
					error={error}
					error_message={error_message}
					isResendLoading={isResendLoading}
				/> */}
                <OtpContainer>
                    <InputOtp
                        type="text"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                    />
                </OtpContainer>
                <Disclaimer className="g-medium">
                    If you are facing any issues to get the OTP, kindly Whatsapp
                    to{" "}
                    <a
                        href="https://wa.me/+918589998713"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: " #5cc66a" }}
                    >
                        +91 871 460 2281
                    </a>
                </Disclaimer>
            </Content>
            <BottomButton
                className="g-medium white"
                to="password"
                onClick={(e) => handleOtp(e)}
            >
                {isLoading ? <TefunLoader /> : "Next"}
            </BottomButton>
        </Container>
    );
};

export default LoginWithOtp;

const Container = styled.div`
    background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/auth/decorator.svg");
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    width: 100%;
    padding: 169px 53px 0 53px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100vh;
    @media (max-width: 980px) {
        width: 100%;
        padding: 169px 49px 0;
    }
    @media (max-width: 640px) {
        padding: 169px 30px 0;
    }
    @media (max-width: 480px) {
        padding: 91px 25px 0;
    }
    @media (max-width: 420px) {
        padding: 91px 12px 0;
    }
    @media (max-width: 360px) {
        padding: 91px 10px 0;
    }
`;
const Logo = styled.img`
    display: none;
    @media (max-width: 640px) {
        display: none;
        width: 60px;
    }
`;
const Title = styled.h4`
    font-size: 25px;
    line-height: 1.4em;
    margin-top: 14px;
    font-family: "gordita_medium";
    @media (max-width: 640px) {
        font-size: 23px;
    }
    @media (max-width: 480px) {
        margin-top: 25px;
        font-size: 20px;
    }
`;
const Description = styled.p`
    margin: 18px 0 40px;
    width: 94%;
    font-family: "gordita_medium";
    @media (max-width: 480px) {
        font-family: unset;
        width: 100%;
    }
`;
const Content = styled.div`
    position: relative;
`;
const Disclaimer = styled.span`
    color: #4d4e4e;
    font-family: "gordita_medium";
    display: block;
    font-size: 13px;
    margin-top: 35px;
`;
const BottomButton = styled(Link)`
    background: linear-gradient(
        272deg,
        rgba(34, 193, 195, 1) 0%,
        rgba(99, 187, 76, 1) 0%,
        rgba(24, 152, 175, 1) 100%
    );
    display: block;
    border-radius: 6px;
    height: 58px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 30px 0 108px;
    color: #fff;
    font-size: 14px;
    min-height: 50px;
    @media (max-width: 480px) {
        height: 44px;
        font-size: 14px;
    }
`;
const Shape = styled.img`
    max-height: 29px;
    max-width: 29px;
    @media (max-width: 640px) {
        display: none;
    }
`;
const InputOtp = styled.input`
    width: 100%;
    height: 100%;
    padding-left: 10px;
`;
const OtpContainer = styled.div`
    width: 100%;
    height: 40px;
    border: 1px solid #000;
    border-radius: 8px;
`;
