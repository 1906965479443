import React, { useContext } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../context/store";

function Tefun404({ isError, setError }) {
	const navigate = useNavigate();
	const { state } = useContext(Context);
	return (
		<Outer>
			<Container>
				<InnerContainer>
					<ImageContainer>
						<Image
							alt="Error 404"
							src={
								"https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/14-01-2022/404.svg"
							}
						/>
					</ImageContainer>
					<Title>Page not found</Title>
					<Text>
						You seems to have clicked on a broken link or entered a
						URL that doesn't exist on this site.
					</Text>
					{!isError && (
						<Button
							onClick={(e) => {
								e.preventDefault();
								navigate(
									`/tefun/lessons/${state.tefunData.currentTopic}`
								);
								setError(false);
							}}
						>
							{" "}
							Goto lessons{" "}
						</Button>
					)}
				</InnerContainer>
			</Container>
		</Outer>
	);
}

export default Tefun404;
const Outer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 75px;
`;
const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: calc(100vh - 288px);
	padding-right: unset !important;
	padding-bottom: unset !important;
	@media all and (max-width: 768px) {
		padding-top: 0px;
	}
`;
const InnerContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;
const ImageContainer = styled.div`
	width: 43%;
	@media (max-width: 1440px) {
		width: 43%;
	}
	@media (max-width: 840px) {
		min-width: 200px;
	}
`;
const Image = styled.img`
	display: block;
	width: 100%;
`;
const Title = styled.h3`
	font-size: 24px;
	color: #363636;
	font-family: "gordita_medium";

	@media only screen and (max-width: 640px) {
		font-size: 21px;
	}
	@media only screen and (max-width: 480px) {
		font-size: 18px;
	}
`;
const Text = styled.p`
	font-size: 16px;
	color: #6ca1ad;
	width: 68%;
	text-align: center;
	margin: 5px 0 19px;
	font-family: "gordita_regular";
	@media only screen and (max-width: 1280px) {
		width: 71%;
	}
	@media only screen and (max-width: 480px) {
		width: 94%;
	}
`;
const Button = styled.div`
	font-family: "gordita_regular";
	text-align: center;
	font-size: 16px;
	background-color: #56c082;
	color: #fff;
	border-radius: 6px;
	padding: 9px 28px;
	cursor: pointer;
	@media only screen and (max-width: 480px) {
		font-size: 15px;
		padding: 7px 20px;
	}
`;
