import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import axios from "axios";
import { Context } from "../components/context/store";

export default function FlagDropDown({ setCountryselector }) {
  const { state } = useContext(Context);
  const country_code = state.user_data.country_code;
  const [arrayData, setArrayData] = useState([]);
  const [countryCode, setCountrCode] = useState({});
  const country = state.country_details.country;
  return (
    <Container onClick={() => setCountryselector((prev) => !prev)}>
      <ImgContainer>
        <Image
          // src={props.selectedCountry && props.selectedCountry.flag}
          src={country.flag}
          alt="Image"
        />
      </ImgContainer>
      <Icon
        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/auth/arrow.svg"
        alt=""
      />
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  margin: auto 0;
  cursor: pointer;
`;
const ImgContainer = styled.div`
  height: 42px;
  width: 42px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-right: 7px;
  @media (max-width: 480px) {
    height: 36px;
    width: 36px;
  }
`;
const Image = styled.img`
  display: block;
  object-fit: cover;
  width: 100%;
`;
const Icon = styled.img`
  display: block;
  width: 6px;
  transform: rotate(90deg);
  @media (max-width: 480px) {
    width: 5px;
  }
`;
