import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

function MobileAppSection() {
    return (
        <>
            <AppSection>
                <Container className="wrapper">
                    <LeftAppSection>
                        <h3>
                            ടാൽറോപിന്റെ OTT കമ്മ്യൂണിറ്റി പ്ലാറ്റ്‌ഫോമായ
                            യായിലൂടെ തികച്ചും സൗജന്യമായി ഇപ്പോൾ തന്നെ പഠനം
                            ആരംഭിക്കൂ{" "}
                        </h3>
                        <p>
                            <b>Tefun</b>-ലൂടെ ടെക്ക്‌നോളജിയുടെ വിസ്മയലോകം
                            ഇനിയിതാ നിങ്ങളിലേക്ക്. ടെക്ക്-പഠനം ഇനി കൂടുതൽ
                            എളുപ്പമാക്കാൻ <span>yia.ai</span> മൊബൈൽ ആപ്പ്
                            ഡൗൺലോഡ് ചെയ്യൂ.
                        </p>
                        <Store>
                            <Apple
                                target="_blank"
                                href="https://apps.apple.com/in/app/yiaai-ott-community-platform/id1609634713"
                            >
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/tefun/31-01-2021/green-app-play.svg"
                                    alt="apple"
                                />
                            </Apple>
                            <Android
                                target="_blank"
                                href="https://play.google.com/store/apps/details?id=com.yiaai"
                            >
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/tefun/31-01-2021/green-and-play.svg"
                                    alt="Android"
                                />
                            </Android>
                        </Store>
                    </LeftAppSection>
                    <RightAppSection>
                        <AppContainer>
                            <img
                                className="spot"
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/28-07-2022/Group-image.png"
                                alt="spot-bg"
                            />
                        </AppContainer>
                    </RightAppSection>
                </Container>
            </AppSection>
        </>
    );
}

export default MobileAppSection;

const AppSection = styled.section`
    padding: 50px 0;
    @media all and (max-width: 640px) {
        padding: 50px 0;
    }
`;
const Container = styled.div`
    background-color: #f3f9eb;
    background-image: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/tefun/28-01-2022/lines-bg.svg");
    background-position: top 0 left 10%;
    background-repeat: no-repeat;
    background-size: 700px;
    border-radius: 20px;
    padding: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @media all and (max-width: 1080px) {
        flex-direction: column-reverse;
        padding: 50px;
    }
    @media all and (max-width: 640px) {
        padding: 30px;
    }
    @media all and (max-width: 480px) {
        padding: 20px;
    }
`;
const LeftAppSection = styled.div`
    width: 50%;
    @media all and (max-width: 1080px) {
        width: 100%;
        text-align: center;
    }
    h3 {
        font-size: 35px;
        width: 90%;
        font-family: EGGIndulekhaUni;
        margin-bottom: 35px;
        span {
            font-size: 40px;
            @media all and (max-width: 640px) {
                width: 100%;
                font-size: 35px;
            }
            @media all and (max-width: 480px) {
                font-size: px;
            }
        }
        @media all and (max-width: 1420px) {
            width: 100%;
        }
        @media all and (max-width: 1080px) {
            width: 80%;
            margin: 0 auto;
            margin-bottom: 30px;
        }
        @media all and (max-width: 640px) {
            width: 100%;
            font-size: 30px;
        }
        @media all and (max-width: 480px) {
            font-size: 26px;
        }
    }
    p {
        width: 90%;
        font-size: 20px;
        color: #646563;
        margin-bottom: 30px;
        line-height: 1.3em;
        font-family: gordita_regular;
        span {
            font-size: 22px;
        }
        @media all and (max-width: 1080px) {
            width: 100%;
        }
        @media all and (max-width: 640px) {
            font-size: 16px;
        }
    }
`;
const Store = styled.div`
    display: flex;
    width: 80%;
    align-items: center;
    @media all and (max-width: 1420px) {
        width: 100%;
    }
    @media all and (max-width: 1080px) {
        margin: 0 auto;
        justify-content: center;
    }
    @media all and (max-width: 360px) {
        width: 100%;
    }
`;
const Apple = styled.a`
    display: block;
    margin-right: 10px;
    width: 150px;
    @media all and (max-width: 540px) {
        margin-bottom: 20px;
        margin-right: 10px;
    }

    & img {
        display: block;
        width: 100%;
        @media all and (max-width: 768px) {
            width: 80%;
            margin: 0 auto;
        }
        @media all and (max-width: 480px) {
            width: 100%;
        }
    }
`;
const Android = styled(Apple)`
    margin-right: 0;
`;
const RightAppSection = styled.div`
    width: 50%;
    height: 100%;
    @media all and (max-width: 1080px) {
        width: 100%;
    }
`;
const AppContainer = styled.div`
    width: 100%;

    & img.spot {
        display: block;
        width: 53%;
        position: absolute;
        bottom: -20%;
        right: 0%;
        @media all and (max-width: 1380px) {
            width: 57%;
            right: -55px;
        }
        @media all and (max-width: 1080px) {
            position: relative;
            width: 60%;
            margin: 0 auto;
            left: 0;
            right: 0;
        }
        @media all and (max-width: 768px) {
            width: 80%;
        }
    }
`;
