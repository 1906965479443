import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";

import {
	Link,
	useHistory,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
// import queryString from "query-string";
import {
	accountsConfig,
	yiaaiConfig,
} from "../../components/helpers/axiosConfig";
import { Context } from "../../components/context/store";
// import ButtonLoader from "../loaders/ButtonLoader";
import TefunLoader from "../../components/tefun/TefunLoader";
// import auth from "../../../../routing/auth";
// import RequestLoader from "../general/RequestLoader";
// import ColorLogo from "../general/ColorLogo";
// import TalropEdtechHelmet from "../../../../helpers/TalropEdtechHelmet";

// Function used to get values from redux react

const SighnupName = (props) => {
	const navigate = useNavigate();
	// const history = useHistory();
	const { state, dispatch } = useContext(Context);
	const [error, setError] = useState(false);
	const [error_message, setErrorMessage] = useState("");
	const [isLoading, setLoading] = useState(false);
	const [hide, setHide] = useState(true);
	const [name, setName] = useState("");
	const country = state.user_validate.country;
	const number = state.user_validate.number;
	const [NameRes, setNameRes] = useState([]);
	// const
	const [searchParams, setSearchParams] = useSearchParams();
	const handlePasswordShow = () => {
		setHide(!hide);
	};

	const handleName = (e) => {
		setLoading(true);
		e.preventDefault();
		yiaaiConfig
			.post("accounts/signup/set/name/", {
				country: country,
				phone: number,
				name: name,
			})
			.then(function (response) {
				setLoading(false);
				setNameRes(response.data);
				if (response.data.StatusCode === 6000) {
					dispatch({
						type: "USER_CHECK_VALIDATE",
						payload: {
							...state.user_validate,
							name: name,
							action: "setpassword",
						},
					});
					setSearchParams({ action: "set-password" });
				} else if (response.data.StatusCode === 6001) {
					setErrorMessage(response.data.message);
				}
			});
	};

	return (
		<Container>
			<Logo
				alt=""
				src={
					"https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/auth/logo-vertical-white.png"
				}
			></Logo>
			<Content>
				<Shape
					src={
						"https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/auth/shape.svg"
					}
					alt=""
				/>
				<Title className="g-medium">Enter Name</Title>
				<Description className="g-medium">
					Enter your Name for this account
				</Description>
				<InputContainer
					style={{ marginBottom: 0, borderColor: error && "#f46565" }}
				>
					{/* <Icon
						alt=""
						src={
							hide
								? "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/auth/lock.svg"
								: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/auth/unlock.svg"
						}
					/> */}
					<InputField
						className="g-medium"
						autoFocus
						style={{ paddingRight: 15 }}
						// type={hide ? "password" : "text"}
						type="text"
						placeholder="Enter a name"
						onChange={(e) => {
							setName(e.target.value);
							setError(false);
						}}
						value={name}
					/>
					{/* <Icon
						onClick={handlePasswordShow}
						style={{ cursor: "pointer" }}
						src={
							hide
								? "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/auth/eye.svg"
								: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/auth/hide.svg"
						}
						alt=""
					/> */}
				</InputContainer>

				<BottomRow style={{ justifyContent: error && "space-between" }}>
					{error && (
						<ErrorText className="g-medium">
							{error_message}
						</ErrorText>
					)}
					{/* <RowItem
						onClick={(e) => {
							e.preventDefault();
						}}
						to="/auth/login/verify-otp/"
						className="g-medium"
					>
						Login with OTP
					</RowItem> */}
				</BottomRow>
			</Content>

			<BottomButton
				// to="/tech-schooling/"
				// to="/dashboard/"
				className="g-medium white"
				onClick={(e) => handleName(e)}
			>
				{isLoading ? <TefunLoader /> : "Next"}
			</BottomButton>
			{/* <Forgot to="/auth/reset/password/phone/enter/" className="g-medium">
				Forgot password?
			</Forgot> */}
			<Terms href="/tos/">Terms of service</Terms>
		</Container>
	);
};

export default SighnupName;

const Container = styled.div`
	background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/auth/decorator.svg");
	background-repeat: no-repeat;
	background-size: contain;
	position: relative;
	width: 100%;
	padding: 169px 53px 0 53px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: 100vh;
	@media (max-width: 980px) {
		width: 100%;
		padding: 169px 49px 0;
	}
	@media (max-width: 640px) {
		padding: 169px 30px 0;
	}
	@media (max-width: 480px) {
		padding: 91px 25px 0;
	}
	@media (max-width: 420px) {
		padding: 91px 12px 0;
	}
	@media (max-width: 360px) {
		padding: 91px 10px 0;
	}
`;
const Logo = styled.img`
	display: none;
	@media (max-width: 640px) {
		display: none;
		width: 60px;
	}
`;
const Title = styled.h4`
	font-size: 25px;
	line-height: 1.4em;
	margin-top: 14px;
	@media (max-width: 640px) {
		font-size: 23px;
	}
	@media (max-width: 480px) {
		margin-top: 25px;
		font-size: 20px;
	}
`;
const Description = styled.p`
	margin: 18px 0 40px;
	width: 94%;
	font-size: 14px;
	@media (max-width: 480px) {
		width: 100%;
		font-size: 13px;
		margin-top: 7px;
	}
`;
const Content = styled.div``;
const InputContainer = styled.div`
	border: 1px solid;
	border-radius: 7px;
	padding: 15px 18px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #000;
	font-size: 17px;
	width: -webkit-fill-available;
	width: -moz-available;
	position: relative;
	@media (max-width: 480px) {
		margin-bottom: 10px;
		padding: 10px 12px;
		border-color: #5f6367;
	}
`;
const Icon = styled.img`
	max-width: 20px;
	max-height: 20px;
	display: block;
	@media (max-width: 480px) {
		max-width: 17px;
		max-height: 17px;
	}
`;
const InputField = styled.input`
	width: 100%;
	color: #000;
	font-size: 15px;
	padding-left: 15px;
	caret-color: #5cc66a;
	@media (max-width: 480px) {
		width: 106.666666667%;
		padding-left: 16px;
		font-size: 14px;
		transform: scale(0.9375);
		transform-origin: left top;
	}
`;
const BottomRow = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 9px;
	font-size: 15px;
`;
const RowItem = styled(Link)`
	font-size: 13px;
	@media (max-width: 480px) {
		font-size: 12px;
	}
`;
const ErrorText = styled.span`
	font-size: 12px;
	color: #f46565;
	@media (max-width: 480px) {
		font-size: 13px;
	}
`;
const BottomButton = styled.div`
	background: linear-gradient(
		272deg,
		rgba(34, 193, 195, 1) 0%,
		rgba(99, 187, 76, 1) 0%,
		rgba(24, 152, 175, 1) 100%
	);
	display: block;
	border-radius: 6px;
	font-size: 14px;
	height: 58px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 40px;
	color: #fff;
	min-height: 50px;
	@media (max-width: 480px) {
		height: 44px;
		font-size: 13px;
	}
`;
const Shape = styled.img`
	max-height: 29px;
	max-width: 29px;
	@media (max-width: 640px) {
		display: none;
	}
`;
const Forgot = styled(Link)`
	margin: 14px 0 70px;
	font-size: 14px;
	text-align: center;
	color: #5cc66a;
	@media (max-width: 640px) {
		margin: 20px 0 70px;
	}
	@media (max-width: 480px) {
		margin: 20px 0 40px;
		font-size: 13px;
	}
`;
const Terms = styled.a`
	display: block;
	text-align: center;
	border-top: 1px solid #d1dbda;
	padding: 22px 0 55px;
	font-size: 13px;
	@media (max-width: 640px) {
		padding: 22px 0;
	}
`;
