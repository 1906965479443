import React from "react";
import Lottie from "react-lottie";
// import loader from "../../../../assets/lotties/tech-schooling/page-loader.json";
// import loader from "../../assets/page-loader.json";
import loader from "../../assets/lotties/button-loader.json";

export default function TefunLoader() {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: loader,
		rendererSettings: {},
	};
	return <Lottie options={defaultOptions} height={40} width={40} />;
}
