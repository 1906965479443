import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { Link, useSearchParams } from "react-router-dom";
import VideoModal from "../general/VideoModal";
import { Context } from "../../context/store";
import bannerImage from "../../../assets/images/banner-media.png";
import spotlightBanner from "../../../assets/images/spotlight_banner.png";

function Spotlight() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isModal, setIsModal] = useState(false);
    const [isVideoModal, setVideoModal] = useState(false);
    const [videoUrl] = useState(
        "https://player.vimeo.com/external/736439471.m3u8?s=a87dc5a3670084366d4ee1b0e61dd692f3bb3648"
    );
    const {
        state: { user_data },
    } = useContext(Context);

    return (
        <>
            {/* <CampusModal isModal={isModal} setIsModal={setIsModal} /> */}
            <VideoModal
                isVideoModal={isVideoModal}
                setVideoModal={setVideoModal}
                videoUrl={videoUrl}
                videoThumbnail={
                    "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/23-12-2022/spotlight.jpg"
                }
            />
            <SpotSection>
                <SpotBg>
                    <SpotDetails className="details">
                        <Tefun>
                            <img
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/tefun/28-01-2022/Tefun-logo.svg"
                                alt="tefun"
                            />
                        </Tefun>
                        <h4>Technology Fundamental Program</h4>
                        <h3>
                            കേരളത്തിലെ <span>20 ലക്ഷം </span>സ്കൂൾ
                            വിദ്യാർത്ഥികൾക്ക് സൗജന്യമായി ടെക്ക്നോളജി പഠിക്കാം!
                        </h3>
                        <p>
                            ടാൽറോപ്പിന്റെ OTT കമ്മ്യൂണിറ്റി പ്ലാറ്റ്ഫോമായ
                            'യായ്'-ലൂടെ തികച്ചും സൗജന്യമായി ഇപ്പോൾ തന്നെ പഠനം
                            ആരംഭിക്കൂ. <br /> Tefun-ലൂടെ ടെക്ക്നോളജിയുടെ
                            വിസ്മയലോകം ഇനിയിതാ നിങ്ങളിലേക്ക്. ടെക്ക്-പഠനം ഇനി
                            കൂടുതൽ എളുപ്പമാക്കാൻ 'Yiaai' മൊബൈൽ ആപ്പ് ഡൗൺലോഡ്
                            ചെയ്യൂ.
                        </p>
                        <Powered>
                            <img src={spotlightBanner} alt="media" />
                        </Powered>

                        <Subscribe>
                            {user_data.is_verified ? (
                                <SubscribeBtn>Continue Learning</SubscribeBtn>
                            ) : (
                                <SubscribeBtn
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    //     setSearchParams({ action: "login" });
                                    // }}
                                    to=""
                                >
                                    Join for free
                                </SubscribeBtn>
                            )}
                            <RegGred>
                                <CampusReg>Register school</CampusReg>
                            </RegGred>
                        </Subscribe>
                    </SpotDetails>
                    <SpotImgContainer>
                        <SpotImg>
                            <Hero>
                                <img
                                    className="hero"
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/23-12-2022/spotlight.jpg"
                                    alt="Icon"
                                    loading="lazy"
                                />
                            </Hero>
                            <PlayIcon onClick={() => setVideoModal(true)}>
                                <img
                                    className="play"
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/tefun/28-01-2022/play-green.svg"
                                    alt="play"
                                />
                            </PlayIcon>
                            <ArrowIcon
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/tefun/28-01-2022/arrow.svg"
                                alt="arrow"
                            />
                        </SpotImg>
                        <StoreContent>
                            <Store>
                                <App
                                    target="_blank"
                                    href="https://apps.apple.com/in/app/yiaai-ott-community-platform/id1609634713"
                                >
                                    <img
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/tefun/31-01-2021/grey-app-play.svg"
                                        alt="apple"
                                    />
                                </App>
                                <App
                                    target="_blank"
                                    href="https://play.google.com/store/apps/details?id=com.yiaai"
                                >
                                    <img
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/tefun/31-01-2021/grey-and-play.svg"
                                        alt="Android"
                                    />
                                </App>
                            </Store>
                        </StoreContent>
                    </SpotImgContainer>
                </SpotBg>
            </SpotSection>
        </>
    );
}

export default Spotlight;

const SpotSection = styled.section`
    max-width: 1650px;
    margin: 0 auto;
    margin-top: 130px;
    padding-bottom: 80px;
    position: relative;
    @media (max-width: 980px) {
        margin-top: 120px;
        padding-bottom: 50px;
    }
    @media (max-width: 480px) {
        margin-top: 70px;
        padding-bottom: 0px;
    }
    &::after {
        content: "";
        position: absolute;
        width: 200px;
        height: 200px;
        right: 2px;
        bottom: 5%;
        z-index: -1;
        background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/tefun/28-01-2022/elipse.svg")
            no-repeat;
        @media (max-width: 1080px) {
            right: 2px;
            bottom: -1%;
        }
        @media (max-width: 768px) {
            display: none;
        }
    }
`;
const SpotBg = styled.div`
    width: 95%;
    margin: 0 auto;
    border-radius: 35px;
    overflow: hidden;
    background-color: #f3f9eb;
    background-image: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/tefun/28-01-2022/lines-bg.svg");
    background-position: top 0 left 10%;
    background-repeat: no-repeat;
    background-size: 1000px;
    padding: 5% 50px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1080px) {
        flex-wrap: wrap;
        flex-direction: column;
    }
    @media (max-width: 640px) {
        border-radius: 15px;
        padding: 10% 20px;
    }
`;

const Powered = styled.div`
    margin-bottom: 25px;
    width: 80%;
    align-items: center;
    @media (max-width: 1080px) {
        width: 80%;
        margin: 0 auto;
    }
    @media (max-width: 640px) {
        margin: 0 auto;
        text-align: center;
        justify-content: center;
        margin-bottom: 20px;
    }
    @media (max-width: 480px) {
        width: 100%;
    }
    & img {
        display: block;
        width: 100%;
        @media (max-width: 640px) {
            width: 100%;
            margin: 0 auto;
        }
    }
`;
const Steyp = styled.span`
    padding: 5px 10px;
    height: 80px;
    /* width: 300px; */
    background-color: #fff;
    border: 1px solid #8eceab;
    margin-right: 5px;
    border-radius: 10px;
    @media (max-width: 480px) {
        width: 150px;
    }
    & small {
        display: block;
        font-size: 12px;
        font-family: "gordita_medium";
        position: relative;
        margin-bottom: 8px;
        &::after {
            content: "";
            width: 20px;
            border-radius: 5px;
            height: 3px;
            background: #1c9aa9;
            position: absolute;
            bottom: -2px;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
        }
    }

    & img {
        display: block;
        width: 100%;
        @media (max-width: 640px) {
            width: 80%;
            margin: 0 auto;
        }
        @media (max-width: 480px) {
            width: 65%;
        }
    }
`;
const TwentyFour = styled(Steyp)`
    margin-right: 0;
`;
const SpotDetails = styled.div`
    width: 45%;
    position: relative;
    @media (max-width: 640px) {
        text-align: center;
    }
    @media (max-width: 1080px) {
        width: 100%;
        margin-bottom: 50px;
    }
    & h4 {
        font-size: 24px;
        font-family: "gordita_medium";
        margin-bottom: 20px;
        @media (max-width: 640px) {
            width: 100%;
            text-align: center;
            font-size: 22px;
        }
        @media (max-width: 480px) {
            font-size: 15px;
            marginbottom: 12px;
        }
    }
    & h3 {
        font-size: 45px;
        font-family: "EGGIndulekhaUni";
        font-weight: 800;
        max-width: 100%;
        margin-bottom: 30px;
        @media (max-width: 1280px) {
            font-size: 40px;
        }
        @media (max-width: 768px) {
            font-size: 38px;
        }
        @media (max-width: 640px) {
            font-size: 35px;
        }
        @media (max-width: 480px) {
            font-size: 28px;
            margin-bottom: 20px;
        }
        & span {
            font-size: 45px;
            font-family: EGGIndulekhaUni;
            color: #0fa76f;
            @media (max-width: 1480px) {
                font-size: 50px;
            }
            @media (max-width: 1280px) {
                font-size: 45px;
            }
            @media (max-width: 768px) {
                font-size: 40px;
            }
            @media (max-width: 640px) {
                font-size: 35px;
            }
            @media (max-width: 480px) {
                font-size: 28px;
            }
        }
    }
    & p {
        font-size: 18px;
        font-family: "gordita_regular";
        color: #545454;
        width: 90%;
        line-height: 1.3em;
        margin-bottom: 40px;
        @media (max-width: 768px) {
            width: 100%;
        }
        @media (max-width: 640px) {
            font-size: 18px;
        }
        @media (max-width: 480px) {
            font-size: 16px;
            margin-bottom: 20px;
        }
    }
`;
const Subscribe = styled.div`
    display: flex;
    align-items: center;
    margin-top: 30px;
    @media (max-width: 1080px) {
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
    }
`;
const ArrowIcon = styled.img`
    display: block;
    position: absolute;
    left: -15%;
    bottom: -12%;
    width: 25%;
    z-index: 3;
    @media (max-width: 1080px) {
        bottom: 0%;
        left: -9%;
    }
    @media (max-width: 768px) {
        width: 30% !important;
        left: 0;
    }
    @media (max-width: 640px) {
        left: -2px;
        bottom: -5px;
    }
`;

const Tefun = styled.div`
    margin-bottom: 20px;
    @media (max-width: 640px) {
        text-align: center;
        display: flex;
        justify-content: center;
    }
    @media (max-width: 480px) {
        margin-bottom: 5px;
    }
    & img {
        display: block;
        @media (max-width: 640px) {
        }
        @media (max-width: 480px) {
            width: 55%;
        }
    }
`;

const SubscribeBtn = styled.div`
    cursor: pointer;
    display: block;
    margin-right: 30px;
    color: #fff;
    font-size: 18px;
    font-family: "gordita_medium";
    width: 238px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    transition: 0.5s;
    background-size: 200% auto;
    background-image: linear-gradient(
        to right,
        #63bb4c 0%,
        #1898af 51%,
        #63bb4c 100%
    );
    cursor: not-allowed;
    filter: grayscale(1);
    @media (max-width: 1080px) {
        margin-right: 0;
    }
    @media (max-width: 640px) {
        font-size: 15px;
        height: 50px;
    }
    &:hover {
        background-position: right center;
    }
`;
const CampusReg = styled.div`
    display: flex;
    align-items: center;
    color: #0fa76f;
    font-family: gordita_medium;
    font-size: 18px;
    justify-content: center;
    cursor: pointer;
    background-color: #fff;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 8px;
    cursor: not-allowed;

    @media (max-width: 640px) {
        font-size: 15px;
    }
`;
const RegGred = styled.div`
    background-image: linear-gradient(
        to right,
        #63bb4c 0%,
        #1898af 51%,
        #63bb4c 100%
    );
    background-size: 200% auto;
    padding: 2px;
    width: 242px;
    height: 70px;
    border-radius: 10px;
    cursor: not-allowed;
    filter: grayscale(1);
    @media (max-width: 1080px) {
        margin-top: 20px;
    }
    @media (max-width: 640px) {
        height: 50px;
    }
`;
const SpotImgContainer = styled.div`
    width: 53%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    @media (max-width: 1080px) {
        width: 70%;
        margin: 0 auto;
        align-items: center;
    }
    @media (max-width: 768px) {
        width: 100%;
    }
`;
const SpotImg = styled.div`
    position: relative;
`;
const Hero = styled.div`
    overflow: hidden;
    border-radius: 10px;
    transform: rotate(2deg);
    @media (max-width: 1080px) {
        margin-bottom: 50px;
        width: 90%;
        margin: 0 auto 50px;
    }
    & img.hero {
        display: block;
        width: 100%;
        border-radius: 30px;
    }
`;
const PlayIcon = styled.span`
    display: block;
    position: absolute;
    cursor: pointer;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    /* background-image: linear-gradient(to right, #63BB4C 0%,#1898AF  100%); */
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        width: 60px;
        height: 60px;
    }
    & img {
        display: block;
        width: 100%;
    }
`;
const StoreContent = styled.div`
    float: right;
    display: flex;
    align-items: center;
    & small {
        font-size: 20px;
        font-family: "gordita_medium";
        color: #0fa76f;
        margin-right: 20px;
        @media (max-width: 480px) {
            margin-right: 10px;
            font-size: 18px;
        }
    }
`;
const Store = styled.div`
    display: flex;
    width: 100%;
`;
const App = styled.a`
    margin-right: 10px;
    width: 170px;
    display: block;
    @media (max-width: 640px) {
        width: 100%;
    }
    & img {
        display: block;
        width: 100%;
        @media (max-width: 640px) {
            width: 90%;
            margin: 0 auto;
        }
    }
    &:last-child {
        margin-right: 0;
    }
`;
