import React from "react";
import styled from "styled-components";

function Syllabus() {
	const syllabuses = [
		{
			id: 1,
			name: "Artificial Intelligence",
			color: "#E3F4E6",
			image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/04-02-22/ai.jpg",
		},
		{
			id: 2,
			name: "Robotics",
			color: "#FFF8E4",
			image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/04-02-22/robotics.jpg",
		},
		{
			id: 3,
			name: "Metaverse",
			color: "#E2F9DD",
			image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/04-02-22/meta.jpg",
		},
		{
			id: 4,
			name: "3D Printing",
			color: "#EFEAFA",
			image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/04-02-22/3d.jpg",
		},
		{
			id: 5,
			name: "Web 3.0",
			color: "#FFF6ED",
			image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/04-02-22/Group+49584.jpg",
		},
		{
			id: 6,
			name: "AR & VR",
			color: "#EBF2FA",
			image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/04-02-22/ar%26vr.jpg",
		},
		{
			id: 7,
			name: "IoT",
			color: "#E3F4E6",
			image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/04-02-22/iot.jpg",
		},
		{
			id: 8,
			name: "Driverless Cars",
			color: "#F9EEFB",
			image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/04-02-22/car.jpg",
		},
	];
	return (
		<>
			<Container>
				<div className="wrapper">
					<Topsection>
						<h2>പുതിയ ടെക്ക്നോളജികൾ മനസ്സിലാക്കാം</h2>
						<p>
							ടെക്ക്-പഠനം വേറിട്ടതും, ക്രിയാത്മകവുമാക്കാൻ കഴിയുന്ന
							സിലബസ്.
						</p>
					</Topsection>
					<BottomSection>
						<Syllabuses>
							{syllabuses.map((element) => (
								<Card
									style={{ backgroundColor: element.color }}
									key={element.id}
								>
									<Thumb>
										<ThumbImg
											src={element.image}
											alt="thumb"
										/>
									</Thumb>
									<Skill>{element.name}</Skill>
									{element.id === syllabuses.length ? (
										<NumberCard>
											<img
												src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/05-02-2022/card.svg"
												alt="card"
											/>
										</NumberCard>
									) : null}
								</Card>
							))}
						</Syllabuses>
					</BottomSection>
				</div>
			</Container>
		</>
	);
}

export default Syllabus;

const Container = styled.section`
	/* background-color: #fff; */
	text-align: center;
	padding: 50px 0 50px 0;
	@media all and (max-width: 640px) {
		padding: 30px 0 0;
	}
`;
const Topsection = styled.section`
	& h2 {
		font-family: EGGIndulekhaUni;
		position: relative;
		font-size: 45px;
		margin-bottom: 30px;
		color: #2d2d2d;
		margin-bottom: 30px;
		color: #0fa76f;
		@media all and (max-width: 1280px) {
			font-size: 35px;
			margin-bottom: 20px;
		}
		@media all and (max-width: 640px) {
			font-size: 30px;
			margin-bottom: 10px;
		}
		@media all and (max-width: 360px) {
			font-size: 26px;
		}
		& span {
			font-size: 40px;
		}
	}
	& p {
		margin: 0 auto;
		max-width: 500px;
		font-size: 22px;
		font-family: gordita_regular;
		@media all and (max-width: 1280px) {
			font-size: 20px;
		}
		@media all and (max-width: 640px) {
			font-size: 18px;
		}
		@media all and (max-width: 480px) {
			font-size: 16px;
		}
	}
`;
const BottomSection = styled.div`
	margin-top: 40px;
	width: 100%;
	@media all and (max-width: 640px) {
		margin-top: 25px;
	}
`;
const Syllabuses = styled.ul`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	text-align: center;
	@media all and (max-width: 1180px) {
		justify-content: center;
	}
	@media all and (max-width: 480px) {
		grid-gap: 10px;
		justify-content: space-between;
	}
`;
const Card = styled.li`
	width: calc(25% - 20px);
	border-radius: 10px;
	margin: 0px 10px 20px;
	padding: 15px 15px 40px 15px;
	padding: 15px;
	position: relative;
	@media all and (max-width: 1180px) {
		width: calc(33% - 20px);
		min-height: 230px;
	}
	@media all and (max-width: 768px) {
		width: calc(50% - 20px);
		min-height: 160px;
	}
	@media all and (max-width: 480px) {
		width: calc(50% - 5px);
		margin: 0;
		min-height: 170px;
		padding: 10px;
	}
`;
const NumberCard = styled.div`
  position: absolute;
  right: -170px;
  bottom: -41%;

  @media all and (max-width: 980px){
    right: -215px;
    bottom: -29%;
  }
  @media all and (max-width: 768px){
    right: -180px;
    bottom: -10%;
  }
  @media all and (max-width: 640px){
    right: -223px;
    bottom: -43%;
  }

	@media all and (max-width: 980px) {
		right: -215px;
		bottom: -29%;
	}
	@media all and (max-width: 840px) {
		right: -240px;
		bottom: -10%;
	}
	@media all and (max-width: 768px) {
		right: -180px;
		bottom: -10%;
	}
	@media all and (max-width: 640px) {
		right: -223px;
		bottom: -43%;
	}

	& img {
		display: block;
		width: 80%;
		@media all and (max-width: 980px) {
			width: 60%;
		}
		@media all and (max-width: 640px) {
			width: 40%;
		}
	}
`;
const Thumb = styled.div`
	width: 100%;
	overflow: hidden;
	border-radius: 10px;
`;
const ThumbImg = styled.img`
	width: 100%;
	height: 100%;
	display: block;
`;
const Skill = styled.span`
	display: block;
	font-size: 18px;
	margin: 20px 0 5px 0;
	font-family: gordita_medium;

	@media all and (max-width: 540px) {
		font-size: 15px;
	}
	@media all and (max-width: 480px) {
		margin: 10px 0 5px 0;
	}
`;
