import React from "react";
import styled from "styled-components";

function StudentJoin() {
    return (
        <>
            <Joinsec>
                <SecContainer className="wrapper">
                    <TopContainer>
                        <h3>
                            <span>വിദ്യാർത്ഥികൾക്ക്</span>&nbsp; Tefun
                        </h3>
                        <span style={{ display: "inline" }}>
                            {" "}
                            പ്രോഗ്രാമിന്റെ ഭാഗമാകാം
                        </span>
                    </TopContainer>
                    <StudentSection>
                        <LeftSection>
                            <BoxContainer>
                                <Box className="first">
                                    <h4>
                                        <b>Tefun</b> പ്രോഗ്രാം സ്കൂളിൽ ഉദ്ഘാടനം
                                        ചെയ്തതിനുശേഷം വിദ്യാർത്ഥികൾക്ക്{" "}
                                        <b>Tefun</b>-ൽ ജോയിൻ ചെയ്യാവുന്നതാണ്.
                                        {/* <a href='https://yia.ai' target="_blank">(yia.ai)</a>  */}
                                        {/* ഓൺലൈനായി Tefun പ്രോഗ്രാമിന്റെ ഭാഗമാകാം. */}
                                    </h4>
                                    <small className="first">01</small>
                                </Box>
                                <Box className="second">
                                    <h4>
                                        <b>Talrop</b>-ന്റെ Web, Android, iOS
                                        പ്ലാറ്റ്ഫോമുകളിലൂടെ{" "}
                                        <a
                                            href="https://yia.ai"
                                            target="_blank"
                                        >
                                            (yia.ai)
                                        </a>{" "}
                                        ഓൺലൈനായി <b>Tefun</b> പ്രോഗ്രാമിന്റെ
                                        ഭാഗമാകാം.
                                    </h4>
                                    <small className="second">02</small>
                                </Box>
                                <Box className="third">
                                    <h4>
                                        വിദ്യാർത്ഥികളുടെ മൊബൈൽ നമ്പർ
                                        നൽകിയതിനുശേഷം പ്രൊഫൈൽ തിരഞ്ഞെടുത്ത്{" "}
                                        <b>Tefun</b> പ്രോഗ്രാം സ്റ്റാർട്ട്
                                        ചെയ്യാം.
                                    </h4>
                                    <small className="third">03</small>
                                </Box>
                                <Box className="fourth">
                                    <h4>
                                        പ്രൊഫൈൽ ലഭ്യമല്ലെങ്കിൽ വിദ്യാർത്ഥികൾക്ക്
                                        സ്കൂൾ കോഡും ക്ലാസും ഡിവിഷനും നൽകി{" "}
                                        <b>Tefun</b> പ്രോഗ്രാമിൽ പങ്കെടുക്കുവാൻ
                                        സാധിക്കും.
                                    </h4>
                                    <small className="fourth">04</small>
                                </Box>
                                <Box className="fifth">
                                    <h4>
                                        വിദ്യാർത്ഥികൾക്ക്{" "}
                                        <nobr>Virtual Schooling-ലൂടെ </nobr>
                                        ഇഷ്ടമുള്ള സമയത്ത് പഠിക്കാൻ സാധിക്കും.
                                    </h4>
                                    <small className="fifth">05</small>
                                </Box>
                                <Box className="sixth">
                                    <h4>
                                        <b>Tefun</b> പ്രോഗ്രാമിന്റെ
                                        അവസാനഘട്ടമായി പഠനം പൂർത്തീകരിക്കുന്ന ഉടൻ
                                        തന്നെ വിദ്യാർത്ഥികൾക്ക് ഓൺലൈൻ എക്സാം
                                        ഉണ്ടായിരിക്കുന്നതാണ്.
                                    </h4>
                                    <small className="sixth">06</small>
                                </Box>
                                <Box className="seventh">
                                    <h4>
                                        <b>Tefun</b> പ്രോഗ്രാം വിജയകരമായി
                                        പൂർത്തീകരിക്കുന്ന വിദ്യാർത്ഥികൾക്ക്
                                        ഓൺലൈൻ പ്ലാറ്റ്ഫോം മുഖേന ഡിജിറ്റലായി
                                        സർട്ടിഫിക്കറ്റ് നൽകുന്നതാണ്.
                                    </h4>
                                    <small className="seventh">07</small>
                                </Box>
                            </BoxContainer>
                        </LeftSection>
                        <RightSection>
                            <ImgContainer>
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/tefun/28-01-2022/join-img.png"
                                    alt="join-hero"
                                />
                            </ImgContainer>
                        </RightSection>
                    </StudentSection>
                </SecContainer>
            </Joinsec>
        </>
    );
}

export default StudentJoin;

const Joinsec = styled.section`
    padding: 50px 0;
    @media all and (max-width: 640px) {
        padding: 50px 0;
    }
`;
const SecContainer = styled.div`
    & span {
        color: #0fa76f;
        font-size: 40px;
        font-family: EGGIndulekhaUni;
        @media all and (max-width: 1280px) {
            font-size: 30px;
        }
        @media all and (max-width: 640px) {
            font-size: 28px;
        }
        @media all and (max-width: 360px) {
            font-size: 24px;
        }
    }
    & h3 {
        font-size: 40px;
        font-family: gordita_medium;
        /* margin-bottom: 50px; */
        @media all and (max-width: 1280px) {
            font-size: 30px;
        }
        @media all and (max-width: 640px) {
            font-size: 28px;
        }
        @media all and (max-width: 360px) {
            font-size: 24px;
        }
    }
`;
const TopContainer = styled.div`
    @media all and (max-width: 1080px) {
        text-align: center;
    }
`;
const StudentSection = styled.div`
    margin-top: 30px;
    display: flex;
    /* align-items: center; */
    position: relative;
    justify-content: space-between;
    @media all and (max-width: 1080px) {
        flex-direction: column-reverse;
    }
`;
const LeftSection = styled.div`
    width: 52%;
    padding-left: 3%;
    @media all and (max-width: 1080px) {
        padding-left: 0;
        width: 100%;
    }
`;
const BoxContainer = styled.ul`
    width: 100%;
`;
const Box = styled.li`
    background: ${(props) =>
        props.className === "first"
            ? "#fef7ec !important"
            : props.className === "second"
            ? "#eaf3fc !important"
            : props.className === "third"
            ? "#dff6e6 !important"
            : props.className === "fourth"
            ? "#e0f0ff !important"
            : props.className === "fifth"
            ? "#fef7ec !important"
            : props.className === "sixth"
            ? "#eaf3fc !important"
            : props.className === "seventh"
            ? "#dff6e6 !important"
            : null};
    margin-bottom: 20px;
    padding: 15px 15px 15px 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 130px;
    border-radius: 20px;
    position: relative;
    &:last-child {
        margin-bottom: 0;
    }
    @media all and (max-width: 1080px) {
        width: 100%;
    }
    @media all and (max-width: 768px) {
        min-height: 110px;
    }
    @media all and (max-width: 640px) {
        padding: 15px;
    }
    & small {
        position: absolute;
        left: -35px;
        @media all and (max-width: 480px) {
            bottom: -25px;
            left: -25px;
        }
        @media all and (max-width: 480px) {
            height: 100px;
            margin-bottom: 30px;
        }
    }

    & h4 {
        font-size: 18px;
        font-family: gordita_regular;
        line-height: 1.4em;
        & span {
            font-family: gordita_regular;
            @media all and (max-width: 480px) {
                font-size: 18px;
            }
        }
        @media all and (max-width: 480px) {
            font-size: 16px;
        }
    }
    & small {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 3px solid #0fa76f;
        &::after {
            content: "";
            width: 150px;
            transform: rotate(90deg);
            position: absolute;
            border-top: 2px dashed #0fa76f;
            top: 100px;
            left: -90%;
            z-index: -1;
        }
        &.seventh {
            &::after {
                display: none;
            }
        }
        @media all and (max-width: 768px) {
            display: none;
        }
    }
`;
const RightSection = styled.div`
    /* width:46% ;
    display: flex;
    justify-content: center;
    align-items: center; */
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    width: 40%;
    @media all and (max-width: 1080px) {
        align-items: center;
        padding-right: 0;
        width: 100%;
    }
`;
const ImgContainer = styled.div`
    /* width: 90%;
    height: 80%; */
    position: sticky;
    top: 113px;
    & img {
        display: block;
        width: 90%;
        @media all and (max-width: 1080px) {
            width: 50%;
            margin: 0 auto;
            margin-bottom: 50px;
        }
        @media all and (max-width: 768px) {
            width: 60%;
            margin-bottom: 30px;
        }
    }
`;
