import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { yiaaiConfig } from "../../helpers/axiosConfig";

const AssociatedCampus = ({ type }) => {
    const [campus, setCampus] = useState([]);
    const [firstSlideData, setFirstSlideData] = useState([]);
    const [secondSlideData, setSecondSlideData] = useState([]);

    // console.log(firstSlideData, "----firstSlideData-----");
    // console.log(secondSlideData, "----secondSlideData-----");

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 2,
        // autoplay: true,
        speed: 4000,
        autoplaySpeed: 4000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    infinite: true,
                },
            },
            {
                breakpoint: 1080,
                settings: {
                    slidesToShow: 3,
                    infinite: true,
                },
            },
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 3,
                    infinite: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    infinite: true,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    infinite: true,
                },
            },
        ],
    };
    const second_settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        // autoplay: true,
        speed: 4000,
        autoplaySpeed: 4000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    infinite: true,
                },
            },
            {
                breakpoint: 1080,
                settings: {
                    slidesToShow: 3,
                    infinite: true,
                },
            },
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 3,
                    infinite: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    infinite: true,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    infinite: true,
                },
            },
        ],
    };

    useEffect(() => {
        fetchData();
    }, [type]);

    const fetchData = () => {
        yiaaiConfig
            .get(`campuses/`, {
                params: {
                    program: type,
                },
            })
            .then((response) => {
                let { StatusCode, data } = response.data;
                setCampus(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        divideCampus();
    }, [campus]);

    const divideCampus = () => {
        if (campus) {
            const campusHalf = campus && campus.length / 2;
            setFirstSlideData(campus.slice(0, campusHalf));
            setSecondSlideData(campus.slice(campusHalf, campus.length));
        }
    };

    return (
        <CampusSection>
            <Container
                className="wrapper"
                data-aos="fade-up"
                data-aos-once="true"
            >
                <Title>
                    <span>Associated</span> Campuses
                </Title>
                <Description>
                    Various institutions have joined hands with us, to train
                    their students and improve their employability skills, along
                    with moulding them to be future entrepreneurs.
                </Description>
                <SlidersContainer>
                    <CollageLogoSlider>
                        <Slider {...settings}>
                            {firstSlideData.map(
                                (data, index) =>
                                    data.photo && (
                                        <CampusCard key={data.id}>
                                            {/* <Top> */}

                                            <Image>
                                                {data.photo && (
                                                    <img
                                                        src={data.photo}
                                                        alt="Icon"
                                                    />
                                                )}
                                            </Image>

                                            {/* <Logo>
										<img src={data.logo} alt="Icon" />
									</Logo> */}
                                            <Campusname>
                                                {data.campus}
                                            </Campusname>
                                            {/* </Top> */}
                                            <CampusLocation>
                                                <img
                                                    src={
                                                        "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/19-04-2022/Location.svg"
                                                    }
                                                    alt="Icon"
                                                />
                                                {data.district}
                                            </CampusLocation>
                                        </CampusCard>
                                    )
                            )}
                        </Slider>
                    </CollageLogoSlider>
                    <CollageLogoSlider>
                        <Slider {...second_settings}>
                            {secondSlideData.map(
                                (data, index) =>
                                    data.photo && (
                                        <CampusCard key={data.id}>
                                            {/* <Top> */}
                                            <Image>
                                                <img
                                                    src={data.photo}
                                                    alt="Icon"
                                                />
                                            </Image>
                                            {/* <Logo>
										<img src={data.logo} alt="Icon" />
									</Logo> */}
                                            <Campusname>
                                                {data.campus}
                                            </Campusname>
                                            {/* </Top> */}
                                            <CampusLocation>
                                                <img
                                                    src={
                                                        "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/19-04-2022/Location.svg"
                                                    }
                                                    alt="Icon"
                                                />
                                                {data.district}
                                            </CampusLocation>
                                        </CampusCard>
                                    )
                            )}
                        </Slider>
                    </CollageLogoSlider>
                </SlidersContainer>
                <BottomText>
                    And <span>300+</span> campuses all over Kerala
                </BottomText>
            </Container>
            <FadeGradient></FadeGradient>
            <FadeGradientRight></FadeGradientRight>
        </CampusSection>
    );
};

export default AssociatedCampus;
const Top = styled.div``;
const Image = styled.div`
    border-radius: 4px;
    overflow: hidden;
    height: 150px;
    width: 100%;
    display: flex;

    img {
        border-radius: 4px;
        height: 150px;
        width: 215px;
        display: block;
    }
`;

const CampusSection = styled.section`
    padding: 100px 0 0;
    background-color: #f9fcf5;
    position: relative;
    @media all and (max-width: 480px) {
        padding: 50px 0;
    }
`;
const Container = styled.div`
    text-align: center;
    padding-bottom: 100px;
    @media all and (max-width: 480px) {
        padding-bottom: 50px;
    }
`;
const Title = styled.h3`
    font-family: gordita_medium;
    position: relative;
    font-size: 40px;
    margin-bottom: 30px;
    color: #2d2d2d;
    margin-bottom: 30px;
    @media all and (max-width: 1280px) {
        font-size: 30px;
        margin-bottom: 20px;
    }
    @media all and (max-width: 640px) {
        font-size: 28px;
    }
    @media all and (max-width: 360px) {
        font-size: 24px;
    }
    & span {
        color: #0fa76f;
        @media all and (max-width: 1280px) {
            font-size: 30px;
            margin-bottom: 20px;
        }
        @media all and (max-width: 640px) {
            font-size: 28px;
        }
        @media all and (max-width: 360px) {
            font-size: 24px;
        }
    }
`;
const Description = styled.p`
    margin: 0 auto;
    max-width: 700px;
    font-size: 16px;
    @media all and (max-width: 1280px) {
        font-size: 15px;
    }
    @media all and (max-width: 360px) {
        font-size: 13px;
    }
`;

const CollageLogoSlider = styled.div`
    margin-bottom: 30px;
    /* overflow: hidden; */

    .slick-slide {
        width: unset !important;
    }
    .slick-slide > div {
        @media all and (max-width: 480px) {
            width: 150px !important;
        }
    }
    @media all and (max-width: 1280px) {
        margin-bottom: 20px;
    }
`;
const CampusLogo = styled.span`
    display: block;
    width: 100%;
    border-radius: 50%;
    overflow: hidden;
    padding-left: 60px;
    img {
        display: block;
        width: 100%;
    }
    @media all and (max-width: 400px) {
        padding: 0 10px;
    }
`;

const SlidersContainer = styled.div`
    overflow: hidden;
    margin-top: 50px;
    position: relative;
    @media all and (max-width: 1280px) {
        margin-top: 40px;
    }
    @media all and (max-width: 400px) {
        padding-top: 30px;
    }
`;
const CampusCard = styled.div`
    padding: 0 10px;
    /* display: flex !important;
	flex-direction: column;
	align-items: self-start;
	justify-content: space-between; */
    @media all and (max-width: 400px) {
        padding: 0 15px;
    }
`;
const Logo = styled.span`
    display: block;
    width: 70px;
    min-width: 70px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    img {
        display: block;
        width: 100%;
    }
`;
const Campusname = styled.h4`
    line-height: 1.2;
    font-size: 14px;
    font-family: gordita_medium;
    text-align: left;
    margin-top: 18px;
`;
const FadeGradient = styled.div`
    width: 200px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    background: linear-gradient(
        90deg,
        #ffffff 0%,
        rgba(245, 248, 248, 0.01) 100%
    );
`;
const FadeGradientRight = styled.div`
    width: 200px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(
        90deg,
        rgba(245, 248, 248, 0.01) 0%,
        #ffffff 100%
    );
`;

const CampusLocation = styled.p`
    margin-top: 4px;
    font-size: 14px;
    font-family: gordita_regular;
    text-align: left;
    color: #0fa76f;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
        transform: translateY(-2px);
        margin-right: 6px;
        width: 12px;
        filter: invert(294%) sepia(93%) saturate(1416%) hue-rotate(49deg)
            brightness(119%) contrast(52%);
    }
    @media all and (max-width: 360px) {
        font-size: 13px;
    }
`;
const BottomText = styled.h4`
    font-size: 22px;
    font-family: gordita_medium;
    & span {
        color: #0fa76f;
    }
    @media all and (max-width: 640px) {
        font-size: 20px;
    }
    @media all and (max-width: 480px) {
        font-size: 18px;
    }
`;
