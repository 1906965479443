import React, { useContext, useEffect, useState, lazy } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { Context } from "../../../context/store";
import PrivateRoute from "../../../routing/routes/PrivateRoute";
import LandingPage from "../../../web/screens/LandingPage";
const PageLoader = lazy(() => import("../../../includes/PageLoader"));
const Header = lazy(() => import("../../../includes/Header"));
const TefunExamPage = lazy(() => import("../../../tefun/TefunExamPage"));
const Tefun404 = lazy(() => import("../../../tefun/Tefun404"));
const TefunExamCompletion = lazy(() =>
    import("../../../tefun/TefunExamCompletion")
);
const TefunExamLocked = lazy(() => import("../../../tefun/TefunExamLocked"));
const Questions = lazy(() => import("../../../tefun/Questions"));
const TefunLessonListPage = lazy(() =>
    import("../../../tefun/TefunLessonListPage")
);
const NewStudentDetails = lazy(() =>
    import("../../../tefun/NewStudentDetails")
);
const ProfileSelector = lazy(() => import("../../../tefun/ProfileSelector"));
const TefunCertificate = lazy(() => import("../../../tefun/TefunCertificate"));

function TefunRouter() {
    const { state } = useContext(Context);
    const {
        isStarted,
        isLoading,
        examStarted,
        courseCompleted,
        // examScheduled,
        // currentTopic,

        isStudentExists,
        examCompleted,
    } = state.tefunData;
    const {
        // isStarted,
        // isLoading,
        // examStarted,
        // courseCompleted,
        // // examScheduled,
        currentTopic,

        // isStudentExists,
        // examCompleted,
    } = state;
    const [isTemp, setTemp] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setTemp(true);
        }, 700);
    });

    const navigate = useNavigate();

    return !isLoading && isTemp ? (
        <>
            <Header />
            <Routes>
                <Route>
                    <Route
                        path="/"
                        element={
                            isStarted ? (
                                <Navigate to={`lessons/${currentTopic}`} />
                            ) : !isStarted && isStudentExists ? (
                                <Navigate to="/tefun/select-profile" />
                            ) : (
                                <Navigate to="/tefun/student-details" />
                            )
                        }
                    />
                    <Route path="explore" element={<LandingPage />} />
                    <Route
                        path="select-profile"
                        element={
                            <PrivateRoute>
                                {!isStarted && isStudentExists ? (
                                    <ProfileSelector />
                                ) : !isStarted && !isStudentExists ? (
                                    <Navigate to="/tefun/student-details" />
                                ) : isStarted ? (
                                    <Navigate to={`/lessons/${currentTopic}`} />
                                ) : (
                                    <Navigate to="/tefun" />
                                )}
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="student-details"
                        element={
                            <PrivateRoute>
                                {!isStarted ? (
                                    <NewStudentDetails />
                                ) : isStarted && isStudentExists ? (
                                    <Navigate to={`/lessons/${currentTopic}`} />
                                ) : !isStarted && isStudentExists ? (
                                    <Navigate to="/tefun/select-profile" />
                                ) : (
                                    <Navigate to="/tefun" />
                                )}
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="lessons/:id"
                        element={
                            <PrivateRoute>
                                <TefunLessonListPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="exam"
                        element={<Navigate to="/exam/start" />}
                    />
                    <Route
                        path="exam/start"
                        element={
                            <PrivateRoute>
                                {examStarted &&
                                // examScheduled &&
                                !examCompleted &&
                                courseCompleted ? (
                                    <Navigate to="/tefun/exam/questions" />
                                ) : !examStarted &&
                                  //   examScheduled &&
                                  !examCompleted &&
                                  courseCompleted ? (
                                    <TefunExamPage />
                                ) : (
                                    <TefunExamLocked type="start" />
                                )}
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="exam/questions"
                        element={
                            <PrivateRoute>
                                {!examStarted &&
                                //  !examScheduled ?
                                !courseCompleted ? (
                                    <Navigate
                                        to={`/lessons/${currentTopic}/`}
                                    />
                                ) : !examStarted &&
                                  //   examScheduled &&
                                  courseCompleted ? (
                                    <Navigate to="/tefun/exam/start" />
                                ) : examStarted &&
                                  //   examScheduled &&
                                  courseCompleted ? (
                                    <Questions />
                                ) : (
                                    <Navigate
                                        to={`/lessons/${currentTopic}/`}
                                    />
                                )}
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="exam/completion"
                        element={
                            <PrivateRoute>
                                {examCompleted ? (
                                    <TefunExamCompletion />
                                ) : (
                                    <TefunExamLocked />
                                )}
                            </PrivateRoute>
                        }
                    />
                    {/* {examCompleted ? ( */}
                    {/* <Route
						path="tefun-program/certificate"
						element={
							// <PrivateRoute>
							<TefunCertificate />
							// </PrivateRoute>
						}
					/> */}
                    {/* ) : null} */}

                    <Route
                        path="*"
                        element={
                            <Navigate
                                to={`/tefun/lessons/${state.tefunData.currentTopic}`}
                            />
                        }
                    />
                    {/* <Route path="*" element={<Tefun404 />} /> */}
                </Route>
            </Routes>
        </>
    ) : (
        <PageLoader />
    );
}

export default TefunRouter;
