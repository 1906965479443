import React, { useState, useEffect } from "react";
import styled from "styled-components";
import StoriesCard from "../includes/StoriesCard";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
import VideoModal from "../general/VideoModal";
import { yiaaiConfig } from "../../helpers/axiosConfig";

const AboutProgram = () => {
	const [videoUrl, setVideoUrl] = useState({});
	const [videoThumbnail, setVideoThumbnail] = useState({});
	const [loading, setLoading] = useState(false);
	const [isVideoModal, setVideoModal] = useState(false);
	const [testimonial, setTestimonial] = useState([]);

	useEffect(() => {
		const getData = () => {
			yiaaiConfig
				.get("testimonials/", {})
				.then((response) => {
					const { StatusCode, data } = response.data;
					if (StatusCode === 6000) {
						setTestimonial(data);
						setLoading(false);
					} else {
						setLoading(false);
					}
				})
				.catch((error) => {
					setLoading(false);
				});
		};
		getData();
	}, []);

	return (
		<Container className="wrapper">
			<VideoModal
				isVideoModal={isVideoModal}
				setVideoModal={setVideoModal}
				videoUrl={videoUrl}
				videoThumbnail={videoThumbnail}
			/>
			<Title>
				<span>TEFUN-ന്</span>
				<br />
				<small>ആശംസകളോടെ </small>
			</Title>
			<StudentsContainer>
				{testimonial.map((data) => (
					<StudentCardCover key={data.id}>
						<StoriesCard
							data={data}
							setVideoModal={setVideoModal}
							setVideoUrl={setVideoUrl}
							setVideoThumbnail={setVideoThumbnail}
						/>
					</StudentCardCover>
				))}
			</StudentsContainer>
			{/* <ResStudentsContainer>
                <Slider {...settings}>
                    {students.map((data) => (
                        <StudentCardCover key={data.id}>
                            <WatchStoriesStudentsCard
                                data={data}
                                setVideoUrl={setVideoUrl}
                                setModal={setModal}
                            />
                        </StudentCardCover>
                    ))}
                    <Ellipise>
                        <img src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/21-01-2022/light-blue-ellipise.svg" />
                    </Ellipise>
                </Slider>
            </ResStudentsContainer> */}
		</Container>
	);
};

export default AboutProgram;

const Container = styled.div`
	padding: 0px 8%;
	position: relative;
	#invite-video .player-wrapper {
		position: relative;
		padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
	}

	#invite-video .react-player {
		position: absolute;
		top: 0;
		left: 0;
	}
	@media all and (max-width: 1280px) {
		padding: 80px 8%;
	}
	@media all and (max-width: 1100px) {
		padding: 70px 8%;
	}
	@media all and (max-width: 768px) {
		padding: 60px 5%;
	}
	@media all and (max-width: 640px) {
		padding: 50px 0 0px;
	}
`;

const Title = styled.h2`
	color: #2d2d2d;
	text-align: center;
	margin-bottom: 50px;
	font-family: EGGIndulekhaUni;
	font-size: 45px;
	span {
		color: #0fa76f;
	}
	small {
	}
	@media all and (max-width: 640px) {
		margin-bottom: 15px;
		font-size: 36px;
	}
	@media all and (max-width: 480px) {
		margin-bottom: 10px;
		font-size: 34px;
	}
	@media all and (max-width: 360px) {
		margin-bottom: 10px;
		font-size: 32px;
	}
`;

const StudentsContainer = styled.div`
	display: flex;
	justify-content: center;
	margin: 0 -10px;
	flex-wrap: wrap;
	position: relative;
	@media all and (max-width: 480px) {
		/* display: none; */
		margin: 0 -5px;
	}
`;

const StudentCardCover = styled.div`
	width: calc(25% - 20px);
	margin: 0 10px;
	margin-bottom: 30px;
	border-radius: 10px;
	overflow: hidden;
	@media all and (max-width: 1100px) {
		width: calc(33% - 20px);
	}
	@media all and (max-width: 768px) {
		width: calc(50% - 20px);
	}
`;

const Ellipise = styled.span`
	display: block;
	width: 300px;
	position: absolute;
	right: -50px;
	bottom: -50px;
	z-index: -1;
	img {
		display: block;
		width: 100%;
	}
	@media all and (max-width: 1280px) {
		width: 240px;
	}
	@media all and (max-width: 768px) {
		display: none !important;
	}
`;

const ResStudentsContainer = styled.div`
	display: none;
	@media all and (max-width: 480px) {
		/* display: block; */
	}
`;
