import React, { useEffect, useContext } from "react";
import Footer from "./Footer";
import styled from "styled-components";
import Spotlight from "./Spotlight";
import Syllabus from "./Syllabus";
import Topbar from "./Topbar";
import AboutProgram from "./AboutProgram";
import Certification from "./Certification";
import JoinSection from "./JoinSection";
import MobileAppSection from "./MobileAppSection";
import FAQSection from "./FaqSection";
import AssociatedCampus from "./AssociatedCampus";
import { Context } from "../../context/store";
import NewSpotlight from "./NewSpotlight";

function LandingPage() {
    const { dispatch } = useContext(Context);
    useEffect(() => {
        dispatch({
            type: "UPDATE_HEADER_HIDDEN",
            value: true,
        });

        return () => {
            dispatch({
                type: "UPDATE_HEADER_HIDDEN",
                value: false,
            });
        };
    }, []);

    return (
        <Container>
            <NewSpotlight />
            {/* <Topbar />
			<Spotlight /> */}
            {/* <AboutProgram />
            <AssociatedCampus />
            <Syllabus />
            <Certification />
            <JoinSection />
            <MobileAppSection />
            <FAQSection />
            <Footer /> */}
        </Container>
    );
}

export default LandingPage;

const Container = styled.div`
    width: 100%;
    background-color: #f3f9eb;
    height: 100vh;
`;
