import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Link, useSearchParams } from "react-router-dom";
import { Context } from "../../context/store";
function Topbar() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [navbar, setNavbar] = useState(false);
    const {
        state: { user_data },
    } = useContext(Context);

    const handleNavbar = () => {
        if (window.scrollY >= 50) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };
    window.addEventListener("scroll", handleNavbar);
    return (
        <>
            <TopBar className={navbar && "active"}>
                <NavContainer className="wrapper">
                    <LeftSection>
                        <h1>
                            <Logo
                                to={`${
                                    user_data.is_verified
                                        ? "/tefun/explore"
                                        : "/"
                                }`}
                            >
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/tefun/28-01-2022/logo.svg"
                                    alt="Logo"
                                />
                            </Logo>
                        </h1>
                    </LeftSection>
                    <RightSection>
                        {user_data.is_verified ? (
                            <LinkButton to="/tefun">
                                Continue Learning
                            </LinkButton>
                        ) : (
                            <SignInButton
                            // onClick={() =>
                            //     setSearchParams({ action: "login" })
                            // }
                            >
                                Join Now
                            </SignInButton>
                        )}
                    </RightSection>
                </NavContainer>
            </TopBar>
        </>
    );
}

export default Topbar;
const TopBar = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    /* height: 150px; */
    padding: 10px 0 1% 0;
    transition: 0.5s;
    background: #fff;
    &.active {
        box-shadow: 0 16px 24px rgb(0 0 0 / 3%);
        background-color: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(8px);
    }
`;
const NavContainer = styled.div`
    width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const LeftSection = styled.div`
    /* @media (max-width: 420px) {
		width: 39%;
	} */
`;
const Logo = styled(Link)`
    cursor: pointer;
    & img {
        display: block;
        width: 90%;
        @media (max-width: 640px) {
            width: 80%;
        }
        @media (max-width: 768px) {
            width: 60%;
        }
    }
`;
const RightSection = styled.div`
    display: flex;
    align-items: flex-end;
`;
const SignInButton = styled.div`
    cursor: not-allowed;
    filter: grayscale(1);
    display: block;
    color: #fff;
    font-size: 16px;
    padding: 15px 30px;
    font-family: "gordita_medium";
    border-radius: 5px;
    transition: 0.5s;
    background-size: 200% auto;
    background-image: linear-gradient(
        to right,
        #63bb4c 0%,
        #1898af 51%,
        #63bb4c 100%
    );
    &:hover {
        background-position: right center;
        /* text-decoration: none; */
    }
    @media (max-width: 640px) {
        padding: 12px 25px;
    }
    @media (max-width: 480px) {
        padding: 10px 22px;
        font-size: 14px;
    }
`;
const LinkButton = styled(Link)`
    display: block;
    color: #fff;
    font-size: 16px;
    padding: 15px 30px;
    font-family: "gordita_medium";
    border-radius: 5px;
    transition: 0.5s;
    background-size: 200% auto;
    background-image: linear-gradient(
        to right,
        #63bb4c 0%,
        #1898af 51%,
        #63bb4c 100%
    );
    &:hover {
        background-position: right center;
        /* text-decoration: none; */
    }
    @media (max-width: 640px) {
        padding: 12px 25px;
    }
    @media (max-width: 480px) {
        text-align: center;
        padding: 10px 19px;
        font-size: 14px;
    }
    @media (max-width: 360px) {
        padding: 10px 15px;
        width: 190px;
    }
`;
const HandBurger = styled.span`
    cursor: pointer;
    display: block;
    & img {
        display: block;
        width: 100%;
        @media (max-width: 640px) {
            width: 90%;
        }
        @media (max-width: 480px) {
            width: 70%;
        }
    }
`;
