import React, { useContext, useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Context } from "../context/store";
import { yiaaiConfig } from "../helpers/axiosConfig";
import Lottie from "react-lottie";
import animationDataSuccess from "../../assets/lotties/tick.json";
import animationDataError from "../../assets/lotties/error.json";

export default function UpdateDetailModal({
	isEdit,
	setIsEdit,
	modalType,
	setModalType,
}) {
	const {
		state: {
			user_data: { name: user_name, phone },
			campus_data: { campus_name: campus, division, course },
			user_validate: {
				selected_country: { phone_code: code },
			},
		},
	} = useContext(Context);
	const { state, dispatch } = useContext(Context);
	const access_token = state.user_data.access_token;
	const [selectedName, setSelectedName] = useState(user_name);
	const [selectedClass, setSelectedClass] = useState(course);
	const [selectedDivision, setSelectedDivision] = useState(division);
	const [successLabelMessage, setSuccessLabelMessage] = useState("");
	const [successTitleMessage, setSuccessTitleMessage] = useState("");
	const [successStatus, setSuccessStatus] = useState(true);

	const defaultOptions = {
		loop: false,
		autoplay: true,
		animationData: successStatus
			? animationDataSuccess
			: animationDataError,
		rendererSettings: {},
	};

	const updateName = () => {
		yiaaiConfig
			.post(
				`accounts/update-name/`,
				{ name: selectedName },
				{
					headers: {
						Authorization: "Bearer " + access_token,
					},
				}
			)
			.then((response) => {
				setModalType("update");
				setSuccessTitleMessage(response.data.data.title);
				setSuccessLabelMessage(response.data.data.message);
				if (response.data.StatusCode === 6000) {
					setSuccessStatus(true);
					dispatch({
						type: "UPDATE_USER_DATA",
						payload: {
							name: selectedName,
						},
					});
				}
				if (response.data.StatusCode === 6001) {
					setSuccessStatus(false);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const updateClass = () => {
		yiaaiConfig
			.post(
				`accounts/update-class/`,
				{ course: selectedClass },
				{
					headers: {
						Authorization: "Bearer " + access_token,
					},
				}
			)
			.then((response) => {
				setModalType("update");
				setSuccessTitleMessage(response.data.data.title);
				setSuccessLabelMessage(response.data.data.message);
				if (response.data.StatusCode === 6000) {
					setSuccessStatus(true);
					dispatch({
						type: "UPDATE_CAMPUS",
						payload: {
							course: selectedClass,
						},
					});
				}
				if (response.data.StatusCode === 6001) {
					setSuccessStatus(false);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const updateDivision = () => {
		yiaaiConfig
			.post(
				`accounts/update-division/`,
				{ division: selectedDivision },
				{
					headers: {
						Authorization: "Bearer " + access_token,
					},
				}
			)
			.then((response) => {
				setModalType("update");
				setSuccessTitleMessage(response.data.data.title);
				setSuccessLabelMessage(response.data.data.message);
				if (response.data.StatusCode === 6000) {
					setSuccessStatus(true);
					dispatch({
						type: "UPDATE_CAMPUS",
						payload: {
							division: selectedDivision,
						},
					});
				}
				if (response.data.StatusCode === 6001) {
					setSuccessStatus(false);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	function useOutsideAlerter(ref) {
		useEffect(() => {
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					setIsEdit(false);
				}
			}
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	}

	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef);

	return (
		<ModalContainer className={isEdit ? "active" : ""}>
			{modalType === "ChangeName" ? (
				<Modal className={isEdit ? "active" : ""} ref={wrapperRef}>
					<Title>Edit Name</Title>
					<Description>
						We can provide you the certificate with this name.
					</Description>
					<NameCover>
						<ProfileIcon
							src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/profile/profile.svg"
							alt="Icon"
						/>
						<Name
							type="text"
							placeholder="Your Name"
							value={selectedName}
							onChange={(e) => setSelectedName(e.target.value)}
						/>
					</NameCover>
					<ButtonContainer>
						<Button onClick={() => setIsEdit(false)}>Cancel</Button>
						<Button
							className="save"
							onClick={() => {
								updateName();
							}}
						>
							Save
						</Button>
					</ButtonContainer>
					<DarkGreenShade></DarkGreenShade>
					<LightGreenShade></LightGreenShade>
				</Modal>
			) : modalType === "ChangeClass" ? (
				<Modal className={isEdit ? "active" : ""} ref={wrapperRef}>
					<Title>Edit Class </Title>
					<Description>
						We can provide you the certificate with this class.
					</Description>
					<NameCover>
						<ProfileIcon
							src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/profile/profile.svg"
							alt="Icon"
						/>
						<Name
							type="number"
							placeholder="Your Class"
							value={selectedClass}
							onChange={(e) => setSelectedClass(e.target.value)}
						/>
					</NameCover>
					<ButtonContainer>
						<Button onClick={() => setIsEdit(false)}>Cancel</Button>
						<Button
							className="save"
							onClick={() => {
								updateClass();
							}}
						>
							Save
						</Button>
					</ButtonContainer>

					<DarkGreenShade></DarkGreenShade>
					<LightGreenShade></LightGreenShade>
				</Modal>
			) : modalType === "ChangeDivision" ? (
				<Modal className={isEdit ? "active" : ""} ref={wrapperRef}>
					<Title>Edit Division </Title>
					<Description>
						We can provide you the certificate with this division.
					</Description>
					<NameCover>
						<ProfileIcon
							src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/profile/profile.svg"
							alt="Icon"
						/>
						<Name
							type="text"
							placeholder="Your Division"
							value={selectedDivision}
							onChange={(e) =>
								setSelectedDivision(e.target.value)
							}
						/>
					</NameCover>
					<ButtonContainer>
						<Button onClick={() => setIsEdit(false)}>Cancel</Button>
						<Button
							className="save"
							onClick={() => {
								updateDivision();
							}}
						>
							Save
						</Button>
					</ButtonContainer>
					<DarkGreenShade></DarkGreenShade>
					<LightGreenShade></LightGreenShade>
				</Modal>
			) : modalType === "update" ? (
				<>
					<Overlay onClick={() => setIsEdit(false)}></Overlay>
					<SucessModal>
						<SuccesCard>
							<SuccessIcon>
								<Lottie
									options={defaultOptions}
									height={"100%"}
									width={"100%"}
								/>
							</SuccessIcon>
							<SuccessTitle>{successTitleMessage}</SuccessTitle>
							<SuccessLabel>{successLabelMessage}</SuccessLabel>
							<ContinueButton
								onClick={() => {
									setModalType("");
									setIsEdit(false);
								}}
							>
								Continue
							</ContinueButton>
						</SuccesCard>
					</SucessModal>
				</>
			) : (
				""
			)}
		</ModalContainer>
	);
}
const ModalContainer = styled.div`
	position: fixed;
	left: 0;
	top: 0px;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(2px);
	transition: 0.2s;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	&.active {
		opacity: 1;
		transition: 0.2s;
		visibility: visible;
	}
`;
const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 0px;
	width: 100%;
	height: 100vh;
	transition: all 0.3s ease 0s;
	z-index: 2;
`;
const Modal = styled.div`
	width: 650px;
	max-height: 127vh;
	margin: 0 auto;
	background: #fff;
	position: absolute;
	border-radius: 10px;
	transition: 0.2s;
	z-index: 101;
	transform: scale(0);
	position: relative;
	padding: 40px 40px;
	&.active {
		transition: 0.2s;
		transform: scale(1);
	}
	@media all and (max-width: 768px) {
		width: 550px;
	}
	@media all and (max-width: 640px) {
		width: 450px;
	}
	@media all and (max-width: 480px) {
		width: 350px;
		padding: 30px;
	}
	@media all and (max-width: 360px) {
		width: 300px;
	}
`;
const Cover = styled.div``;

const Title = styled.h3`
	font-size: 26px;
	font-family: gordita_medium;
	margin-bottom: 10px;
	@media all and (max-width: 480px) {
		font-size: 22px;
		margin-bottom: 0px;
	}
`;
const Description = styled.p`
	font-size: 16px;
	font-family: "gordita_medium";
	color: #333333;
	max-width: 400px;
	margin-bottom: 20px;
	@media all and (max-width: 480px) {
		font-size: 14px;
		font-family: gordita_regular;
	}
`;
const NameCover = styled.form`
	width: 100%;
	height: 50px;
	background: #fff;
	display: flex;
	align-items: center;
	padding: 0 15px;
	margin-bottom: 30px;
	box-shadow: 0px 3px 37px #00000017;
	border-radius: 5px;
	position: relative;
	img {
		width: 20px;
		display: block;
		margin-right: 15px;
	}
`;
const ProfileIcon = styled.img`
	width: 17px !important;
`;
const Name = styled.input`
	font-size: 16px;
	font-family: "gordita_medium";
	color: #727171;
	flex: 1;
	@media all and (max-width: 480px) {
		font-size: 14px;
	}
`;
const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;
const Button = styled.span`
	width: 150px;
	height: 40px;
	display: flex;
	font-size: 15px;
	justify-content: center;
	align-items: center;
	font-family: gordita_medium;
	cursor: pointer;
	color: #0fa76f;
	border: 2px solid #0fa76f;
	border-radius: 5px;
	&:hover {
		opacity: 0.8;
	}
	&.save {
		color: #fff;
		background-color: #0fa76f;
		margin-left: 20px;
	}
`;

// sucess modal

const SucessModal = styled.div`
	width: 450px;
	max-height: 127vh;
	min-height: 200px;
	margin: 0 auto;
	background: #fff;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	border-radius: 10px;
	transition: 0.5s;
	z-index: 101;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	@media all and (max-width: 480px) {
		width: 330px;
		min-height: 170px;
	}
	@media all and (max-width: 360px) {
		width: 300px;
	}
`;

const SuccesCard = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding: 20px;
`;
const SuccessIcon = styled.div`
	width: 150px;
	height: 150px;
	border-radius: 50%;
	box-shadow: 0 16px 24px rgb(0 0 0 / 10%);
	margin: 0 auto;
	background-color: #fff;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: -80px;
	margin: 0 auto;
	@media all and (max-width: 480px) {
		width: 100px;
		height: 100px;
		top: -65px;
	}
`;
const SuccessTitle = styled.h3`
	text-align: center;
	font-size: 34px;
	font-family: "gordita_medium";
	margin-top: 60px;
	width: 100%;
	@media all and (max-width: 480px) {
		font-size: 26px;
		margin-top: 30px;
	}
`;
const SuccessLabel = styled.p`
	font-size: 18px;
	text-align: center;
	font-family: "gordita_medium";
	color: #3c4852;
	max-width: 350px;
	max-width: 420px;
	@media all and (max-width: 480px) {
		font-size: 16px;
	}
`;
const ContinueButton = styled.span`
	width: 100%;
	height: 50px;
	background-color: #4ba870;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: "gordita_medium";
	margin-top: 30px;
	border-radius: 5px;
	font-size: 20px;
	cursor: pointer;
`;

const DarkGreenShade = styled.div`
	position: absolute;
	width: 100%;
	background-color: #1e7c2f;
	height: 7px;
	bottom: 0;
	left: 0;
	right: 0;
	border-radius: 0 0 10px 10px;
`;
const LightGreenShade = styled.div`
	position: absolute;
	width: 100%;
	background-color: #56c082;
	height: 7px;
	bottom: 7px;
	left: 0;
	right: 0;
`;
