import React from "react";
import styled from "styled-components";

function Certification() {
	return (
		<>
			<Container>
				<CertificateMainContainer className="wrapper">
					<CertificateLeft>
						<h3>Certification</h3>
						<p>
							പ്രോഗ്രാം പൂർത്തിയാക്കുന്ന എല്ലാ വിദ്യാർത്ഥികൾക്കും
							സ്കൂൾ ലോഗോയോടു കൂടിയ കംപ്ലീഷൻ സർട്ടിഫിക്കറ്റുകൾ.
						</p>

						<img
							className="medal"
							src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/tefun/28-01-2022/medal+.svg"
							alt="medal"
						/>
					</CertificateLeft>
					<CertificateRight>
						<CertificateContainer>
							<img
								className="certificate"
								src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-04-2022/certificate.png"
								alt="certificate"
							/>
							<img
								className="arrow"
								src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/tefun/28-01-2022/arrow.svg"
								alt="arrow"
							/>
						</CertificateContainer>
					</CertificateRight>
				</CertificateMainContainer>
			</Container>
		</>
	);
}

export default Certification;

const Container = styled.section`
	padding: 80px 0 80px 0;
	@media all and (max-width: 768px) {
		padding: 80px 0 30px;
	}
	@media all and (max-width: 640px) {
		padding: 80px 0 20px;
	}
`;
const CertificateMainContainer = styled.div`
	display: flex;
	position: relative;
	align-items: center;
	background-image: linear-gradient(to right, #63bb4c 0%, #1898af 100%);
	border-radius: 20px;
	min-height: 300px;
	@media all and (max-width: 1280px) {
		min-height: 250px;
	}
	@media all and (max-width: 1080px) {
		flex-direction: column-reverse;
	}
`;
const CertificateLeft = styled.div`
	width: 55%;
	padding-left: 50px;
	& h3 {
		color: #fff;
		font-size: 35px;
		font-family: "gordita_medium";
		@media all and (max-width: 640px) {
			font-size: 30px;
		}
	}
	& p {
		width: 80%;
		color: #fff;
		font-family: gordita_regular;
		font-size: 20px;
		@media all and (max-width: 1080px) {
			width: 100%;
		}
		@media all and (max-width: 640px) {
			font-size: 18px;
		}
		@media all and (max-width: 640px) {
			font-size: 16px;
		}
	}

	& img.medal {
		position: absolute;
		display: block;
		width: 3%;
		top: 0;
		@media all and (max-width: 1080px) {
			width: 5%;
		}
		@media all and (max-width: 640px) {
			width: 8%;
		}
	}
	@media all and (max-width: 1080px) {
		width: 100%;
		padding: 50px;
		margin-top: 30px;
		text-align: center;
	}
	@media all and (max-width: 640px) {
		padding: 20px;
		margin-top: 50px;
	}
`;
const CertificateRight = styled.div`
	width: 45%;
	height: 100%;
	/* position: relative; */
	@media all and (max-width: 1080px) {
		width: 100%;
		/* height: 100%; */
		text-align: center;
	}
`;
const CertificateContainer = styled.div`
	/* position: relative; */
	& img.certificate {
		position: absolute;
		display: block;
		width: 550px;
		bottom: -63px;
		right: 7%;
		@media all and (max-width: 1280px) {
			width: 470px;
		}
		@media all and (max-width: 1080px) {
			position: relative;
			left: 0;
			right: 0;
			width: 80%;
			margin: 0 auto;
		}
	}

	& img.arrow {
		display: block;
		position: absolute;
		bottom: -130px;
		right: 550px;
		@media all and (max-width: 1650px) {
			right: 545px;
		}
		@media all and (max-width: 1280px) {
			right: 459px;
		}
		@media all and (max-width: 1080px) {
			position: relative;
			display: none;
		}
	}
`;
