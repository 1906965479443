import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";

const Footer = () => {
    // const { user_profile } = useSelector((state) => state);

    const [isHover, setHover] = useState(false);
    return (
        <Cover>
            <Container className="wrapper">
                <FooterSection>
                    <TopSection>
                        <Logo to="/">
                            <img
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/05-02-2022/logo-grey.svg"
                                alt="Icon"
                            />
                        </Logo>
                    </TopSection>
                    <RightSection>
                        <ProgramSection>
                            <SocialMedia>
                                <SocialLinks
                                    target="_blank"
                                    href="https://www.instagram.com/talropworld/"
                                >
                                    <img
                                        class="prime
                                "
                                        src={
                                            "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/instagram-color.svg"
                                        }
                                        alt="Icon"
                                    />
                                    <img
                                        class="secondry"
                                        src={
                                            "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/instagram.svg"
                                        }
                                        alt="Icon"
                                    />
                                </SocialLinks>
                                <SocialLinks
                                    target="_blank"
                                    href="https://www.facebook.com/talropworld/"
                                >
                                    <img
                                        class="prime"
                                        src={
                                            "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/facebook-color.svg"
                                        }
                                        alt="Icon"
                                    />
                                    <img
                                        class="secondry"
                                        src={
                                            "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/facebook.svg"
                                        }
                                        alt="Icon"
                                    />
                                </SocialLinks>
                                <SocialLinks
                                    target="_blank"
                                    href="https://twitter.com/talropworld/"
                                >
                                    <img
                                        class="prime"
                                        src={
                                            "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/twitter-color.svg"
                                        }
                                        alt="Icon"
                                    />
                                    <img
                                        class="secondry"
                                        src={
                                            "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/twitter.svg"
                                        }
                                        alt="Icon"
                                    />
                                </SocialLinks>
                                <SocialLinks
                                    target="_blank"
                                    href="https://www.linkedin.com/company/talrop/"
                                >
                                    <img
                                        class="prime"
                                        src={
                                            "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/linkedin-color.svg"
                                        }
                                        alt="Icon"
                                    />
                                    <img
                                        class="secondry"
                                        src={
                                            "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/linkedin.svg"
                                        }
                                        alt="Icon"
                                    />
                                </SocialLinks>
                                <SocialLinks
                                    target="_blank"
                                    href="https://www.youtube.com/c/talrop/"
                                >
                                    <img
                                        class="prime"
                                        src={
                                            "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/youtube-color.svg"
                                        }
                                        alt="Icon"
                                    />
                                    <img
                                        class="secondry"
                                        src={
                                            "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/youtube.svg"
                                        }
                                        alt="Icon"
                                    />
                                </SocialLinks>
                            </SocialMedia>
                            <Contact>
                                <PhoneLinks
                                    href="https://wa.me/+918589998744"
                                    target="_blank"
                                >
                                    +91 858 999 8744
                                </PhoneLinks>
                                <MailLinks href="mailto:yiaai@talrop.com">
                                    yiaai@talrop.com
                                </MailLinks>
                            </Contact>
                        </ProgramSection>
                    </RightSection>
                    <BottomSection className="wrapper">
                        <CopyRight>
                            © 2023,{" "}
                            <FooterLink
                                href="https://talrop.com/"
                                target="_blank"
                            >
                                Talrop Private Limited
                            </FooterLink>{" "}
                        </CopyRight>
                        <RightSide>
                            <FooterLinks className="last" to="/tos/">
                                Terms of service
                            </FooterLinks>
                            <FooterLinks to="/privacy-policy/">
                                Privacy policy
                            </FooterLinks>
                        </RightSide>
                    </BottomSection>
                </FooterSection>
            </Container>
        </Cover>
    );
};

export default Footer;

const Cover = styled.div`
    background-color: #1e1e1e;
    padding: 100px 0 50px;
    @media all and (max-width: 1100px) {
        padding: 80px 0 40px;
    }
    @media all and (max-width: 640px) {
        padding: 60px 0 25px;
    }
    @media all and (max-width: 480px) {
        padding: 60px 0 20px;
    }
`;
const Container = styled.div`
    text-align: center;
    @media all and (max-width: 980px) {
        grid-template-columns: 1fr;
    }
    @media all and (max-width: 640px) {
        grid-gap: 30px;
    }
`;
const FooterSection = styled.div`
    width: 60%;
    margin: 0 auto;
    @media all and (max-width: 980px) {
        width: 100%;
    }
    /* @media all and (max-width: 768px) {
		width: 80%;
	}
	@media all and (max-width: 670px) {
		width: 90%;
	}
	@media all and (max-width: 580px) {
		width: 100%;
	} */
`;

const TopSection = styled.div`
    width: 100%;
    text-align: center;
`;
const RightSection = styled.div``;
const Logo = styled(Link)`
    width: 100px;
    display: block;
    margin: 0 auto;
    img {
        width: 100%;
        display: block;
    }
    @media all and (max-width: 360px) {
        /* margin: 0 auto; */
    }
`;
const CopyRight = styled.p`
    font-size: 15px;
    color: #aaaaaa;
    @media all and (max-width: 1100px) {
        font-size: 12px;
    }
    @media all and (max-width: 640px) {
        text-align: center;
    }
    @media all and (max-width: 540px) {
        margin-bottom: 20px;
    }
`;
const FooterLink = styled.a`
    display: inline-block;
    font-size: 15px;
    color: #aaaaaa;
`;
const ProgramSection = styled.div`
    @media all and (max-width: 680px) {
        margin-bottom: 30px;
    }
`;
const FooterLinks = styled(Link)`
    display: block;
    margin-bottom: 10px;
    color: #aaaaaa;
    font-size: 15px;
    &.last {
        margin-right: 10px;
        margin-left: 10px;
    }
    @media all and (max-width: 1100px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
`;
const Contact = styled.div`
    display: flex;
    width: 275px;
    justify-content: space-between;
    margin: 0 auto;
`;
const PhoneLinks = styled.a`
    display: block;
    margin-bottom: 10px;
    color: #aaaaaa;
    font-size: 15px;
    @media all and (max-width: 1100px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
`;
const MailLinks = styled.a`
    display: block;
    margin-bottom: 10px;
    color: #aaaaaa;
    font-size: 15px;
    @media all and (max-width: 1100px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
`;
const SocialMedia = styled.div`
    display: flex;
    /* width: 20%; */
    width: 180px;
    margin: 0 auto;
    text-align: center;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 30px;
    @media all and (max-width: 1100px) {
        margin-top: 20px;
    }
`;
const SocialLinks = styled.a`
    display: block;
    margin-right: 15px;
    height: 20px;
    .prime {
        display: none;
    }
    &:hover {
        .prime {
            display: block;
        }
        .secondry {
            display: none;
        }
    }

    img {
        display: block;
        height: 100%;
    }
    &:last-child {
        margin-right: 0;
    }
    @media all and (max-width: 1100px) {
        height: 16px;
    }
    @media all and (max-width: 640px) {
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }
`;

const BottomSection = styled.div`
    margin-top: 50px !important;
    /* width: 60%; */
    /* width: 500px;  */
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    @media all and (max-width: 640px) {
        /* flex-direction: column; */
        width: 100%;
    }
    @media all and (max-width: 540px) {
        flex-direction: column;
        width: 100%;
    }
`;

const RightSide = styled.div`
    display: flex;
    @media all and (max-width: 540px) {
        /* width: 250px; */
        margin: 0 auto;
        justify-content: space-between;
    }
`;
