import React from "react";
import styled from "styled-components";

export default function PageLoader() {
    return (
        <Container>
            <Logo
                src={require("../../assets/images/tefun/logo.svg").default}
                alt="Logo"
            />
        </Container>
    );
}

const Container = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Logo = styled.img`
    display: block;
    width: 80px;
    &:after {
        content: "";
        position: absolute;
        top: -30%;
        right: -200%;
        width: 50%;
        height: 200%;
        opacity: 0;
        transform: rotate(30deg);
        background: rgba(255, 255, 255, 0.13);
        background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0.13) 0%,
            rgba(255, 255, 255, 0.13) 77%,
            rgba(255, 255, 255, 0.5) 92%,
            rgba(255, 255, 255, 0) 100%
        );
        animation: shine 2s 1s;
        @keyframes shine {
            to {
                opacity: 1;
                right: 210%;
            }
        }
    }
`;
