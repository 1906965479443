import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { yiaaiConfig } from "../../helpers/axiosConfig";
import TefunLoader from "../../tefun/TefunLoader";
import OtpModal from "../includes/OtpModal";
import { Link } from "react-router-dom";

function RegisterForm() {
    const [name, setName] = useState("");
    const [campus, setCampus] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [pincode, setPincode] = useState("");
    const [city, setCity] = useState("");
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [course, setCourse] = useState("tefun");
    const [emailvalid, setEmailValid] = useState(true);
    const [countryCode, setCountryCode] = useState("+91");
    const [category, setCategory] = useState("");
    const [categoryId, setCategoryId] = useState("");
    const [isCategory, setIsCategory] = useState(false);
    const [className, setClassName] = useState();
    const [studentCount, setStudentCount] = useState("");
    const [isOtpModal, setIsOtpModal] = useState(false);
    const [logo, setLogo] = useState("");
    const [logoName, setLogoName] = useState("");
    const [classLists, setClassLists] = useState([]);
    const [categoryList, setCategoryList] = useState();
    const [isClassSelect, setIsClassSelect] = useState(false);
    const [classIds, setClassIds] = useState([]);
    const [isClassError, setIsClassError] = useState(false);
    const [classCategoryIds, setClassCategoryIds] = useState([]);

    const Lp = [];

    useEffect(() => {
        const campusClasses = classLists.reduce(
            (prev, next) => prev.concat(next.campus_classes),
            []
        );
        const selectedClasses = campusClasses.filter((e) => e.is_selected);
        const selectedClassesIds = selectedClasses.reduce(
            (prev, next) => prev.concat(next.id),
            []
        );
        setClassCategoryIds(selectedClassesIds);

        const sectionIds = selectedClasses.reduce(
            (prev, next) => prev.concat(next.campus_section),
            []
        );

        setClassIds([...new Set(sectionIds)]);
    }, [isClassSelect]);

    useEffect(() => {
        const getClasses = () => {
            yiaaiConfig
                .get("campuses/campus-sections/", {})
                .then((response) => {
                    const { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        setClassLists(data);
                        setLoading(false);
                    } else {
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                });
        };
        getClasses();
        const getCategory = () => {
            yiaaiConfig
                .get("campuses/campus-categories/", {})
                .then((response) => {
                    const { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        setCategoryList(data);
                        setLoading(false);
                    } else {
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                });
        };
        getCategory();
    }, []);
    const [errors, setErrors] = useState({
        campus: false,
        name: false,
        phoneNo: false,
        city: false,
        pincode: false,
        email: false,
        category: false,
        count: false,
        classCategory: false,
        classIds: false,
        classCategoryIds: false,
    });
    const [isError, setIsError] = useState(false);

    const handleClear = () => {
        setCity("");
        setName("");
        setPhoneNo("");
        setEmail("");
        setCampus("");
        setErrors(false);
        setIsError(false);
        setCategory("");
        setClassName("");
        setStudentCount("");
    };

    const handleOtp = (e) => {
        e.preventDefault();
        setLoading(true);
        let isError = false;
        let isClassError = false;
        setErrors({
            campus: false,
            name: false,
            phoneNo: false,
            pincode: false,
            city: false,
            email: false,
            category: false,
            className: false,
            count: false,
            classIds: false,
            classCategoryIds: false,
        });
        if (!name) {
            setErrors((prev) => ({ ...prev, name: true }));
            isError = true;
        }
        if (phoneNo.length <= 6) {
            setErrors((prev) => ({ ...prev, phoneNo: true }));
            isError = true;
        }
        if (!campus) {
            setErrors((prev) => ({ ...prev, campus: true }));
            isError = true;
        }
        if (!city) {
            setErrors((prev) => ({ ...prev, city: true }));
            isError = true;
        }
        if (!pincode) {
            setErrors((prev) => ({ ...prev, pincode: true }));
            isError = true;
        }
        if (!emailvalid) {
            setErrors((prev) => ({ ...prev, email: true }));
            isError = true;
        }
        if (!email) {
            setErrors((prev) => ({ ...prev, email: true }));
            isError = true;
        }
        if (!category) {
            setErrors((prev) => ({ ...prev, category: true }));
            isError = true;
        }
        if (classIds.length === 0) {
            setErrors((prev) => ({ ...prev, classIds: true }));
            isError = true;
            isClassError = true;
        }
        if (!studentCount) {
            setErrors((prev) => ({ ...prev, count: true }));
            isError = true;
        }
        if (classCategoryIds.length === 0) {
            setErrors((prev) => ({ ...prev, classCategoryIds: true }));
            isError = true;
        }

        if (!isError && phoneNo.length > 6 && !isClassError) {
            yiaaiConfig
                .post(`campuses/free-course-association/enter/phone/`, {
                    contact_phone: phoneNo,
                    country: countryCode,
                })
                .then((response) => {
                    const { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        setLoading(false);
                        setIsOtpModal(true);
                    } else {
                        setLoading(false);
                        setIsError(true);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    setIsError(true);
                });
        } else {
            setIsError(true);
            setLoading(false);
        }
    };

    const validateEmail = (e) => {
        var validator = require("email-validator");
        if (validator.validate(e.target.value)) {
            setEmailValid(true);
        } else {
            setEmailValid(false);
        }
    };
    const handleLogo = (e) => {
        if (e.target.name === "logo") {
            if (e.target.files.length > 0) {
                setLogo(e.target.files[0]);
                setLogoName(e.target.files[0].name);
            }
        }
    };

    const chooseClass = (cl) => {
        setIsClassSelect(!isClassSelect);
        cl.is_selected = !cl.is_selected;
    };

    return (
        <>
            <OtpModal
                campus={campus}
                phoneNo={phoneNo}
                name={name}
                isOtpModal={isOtpModal}
                setIsOtpModal={setIsOtpModal}
                city={city}
                email={email}
                course={course}
                loading={loading}
                setLoading={setLoading}
                setCity={setCity}
                setName={setName}
                setPhoneNo={setPhoneNo}
                setEmail={setEmail}
                setCampus={setCampus}
                countryCode={countryCode}
                categoryId={categoryId}
                className={className}
                studentCount={studentCount}
                classIds={classIds}
                classCategoryIds={classCategoryIds}
                pincode={pincode}
                logo={logo}
            />
            <Container>
                <RegisterFormSection className="wrapper">
                    <TopSection>
                        <h1>
                            <Logo to="/tefun/explore">
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/tefun/28-01-2022/logo.svg"
                                    alt="Logo"
                                />
                            </Logo>
                        </h1>
                    </TopSection>
                    <FormContainer>
                        <LeftSection>
                            <img
                                src={
                                    require("../../../assets/images/landingPage/side.svg")
                                        .default
                                }
                                alt="spot"
                            />
                        </LeftSection>
                        <LeftSection className="mobile">
                            <img
                                src={
                                    require("../../../assets/images/landingPage/640.svg")
                                        .default
                                }
                                alt="spot"
                            />
                        </LeftSection>
                        <RightSection>
                            <Modal
                                autoComplete="off"
                                onSubmit={(e) => handleOtp(e)}
                            >
                                <Title>
                                    Register your school on our technology
                                    platform
                                </Title>
                                <Cover>
                                    <CampusSection>
                                        <label>Name of school*</label>
                                        <Campus
                                            required
                                            type="text"
                                            name="q"
                                            autoComplete="off"
                                            placeholder="Enter your school"
                                            value={campus}
                                            onChange={(e) => {
                                                setCampus(e.target.value);
                                            }}
                                        />
                                    </CampusSection>
                                    <AddressSection>
                                        <Location>
                                            <label>Address*</label>
                                            <LocationInput
                                                required
                                                type="text"
                                                autoComplete="off"
                                                value={city}
                                                placeholder="Place / Street"
                                                onChange={(e) => {
                                                    setCity(e.target.value);
                                                }}
                                            />
                                        </Location>
                                        <District>
                                            <label>Pincode*</label>
                                            <DistrictCover>
                                                <PincodeInput
                                                    required
                                                    type="number"
                                                    value={pincode}
                                                    placeholder="Enter your pincode"
                                                    onChange={(e) => {
                                                        setPincode(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </DistrictCover>
                                        </District>
                                    </AddressSection>
                                    <NameEmail>
                                        <NameContainer>
                                            <label>
                                                Name of contact person*{" "}
                                            </label>
                                            <Name
                                                required
                                                type="text"
                                                autoComplete="off"
                                                placeholder="Enter your name"
                                                value={name}
                                                onChange={(e) => {
                                                    setName(e.target.value);
                                                }}
                                            />
                                        </NameContainer>
                                        <Email>
                                            <label>Email*</label>
                                            <EmailInput
                                                required
                                                autoComplete="off"
                                                type="email"
                                                placeholder="Enter your email"
                                                value={email}
                                                onChange={(e) => {
                                                    setEmail(e.target.value);
                                                    validateEmail(e);
                                                }}
                                            />
                                            {!emailvalid && email !== "" ? (
                                                <ErrorMsg className="half-section">
                                                    {" "}
                                                    enter a valid email
                                                </ErrorMsg>
                                            ) : null}
                                        </Email>
                                    </NameEmail>
                                    <ContactSection>
                                        <Phone>
                                            <label>Phone number*</label>
                                            <PhoneInput
                                                required
                                                autoComplete="off"
                                                type="tel"
                                                placeholder="Enter your number"
                                                value={phoneNo}
                                                onChange={(e) => {
                                                    const re = /^[0-9\b]+$/;
                                                    if (
                                                        e.target.value === "" ||
                                                        re.test(e.target.value)
                                                    ) {
                                                        setPhoneNo(
                                                            e.target.value
                                                        );
                                                        // if (
                                                        //     phoneNo.length !==
                                                        //     10
                                                        // ) {
                                                        //     setIsError(true);
                                                        // }
                                                    }
                                                }}
                                            />
                                            {phoneNo.length < 6 &&
                                            phoneNo !== "" ? (
                                                <ErrorMsg className="half-section">
                                                    {" "}
                                                    Invalid number
                                                </ErrorMsg>
                                            ) : null}
                                        </Phone>
                                        <StudentCount>
                                            <label>Total student count*</label>
                                            <CountInput
                                                type="number"
                                                required
                                                autoComplete="off"
                                                placeholder="Enter count"
                                                value={studentCount}
                                                onChange={(e) => {
                                                    setStudentCount(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </StudentCount>
                                    </ContactSection>
                                    <SchoolTopSection>
                                        <SchoolCategory>
                                            <label>School category*</label>
                                            <CategoryInput
                                                required
                                                type="text"
                                                value={category}
                                                placeholder="Select your category"
                                                onClick={() => {
                                                    setIsCategory(!isCategory);
                                                }}
                                            />
                                            <DownArrow
                                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/tefun/14-01-2022/down.svg"
                                                alt="arrow"
                                                isCategory={isCategory}
                                                onClick={() => {
                                                    setIsCategory(!isCategory);
                                                }}
                                            />
                                            {isCategory && categoryList ? (
                                                <DropListSchool>
                                                    {categoryList.map(
                                                        (element) => (
                                                            <ListNameSchool
                                                                key={element.id}
                                                                onClick={() => {
                                                                    setCategory(
                                                                        element.name
                                                                    );
                                                                    setCategoryId(
                                                                        element.id
                                                                    );
                                                                    setIsCategory(
                                                                        false
                                                                    );
                                                                    setClassName(
                                                                        ""
                                                                    );
                                                                }}
                                                            >
                                                                {element.name}
                                                            </ListNameSchool>
                                                        )
                                                    )}
                                                </DropListSchool>
                                            ) : null}
                                        </SchoolCategory>
                                        <SchoolLogo>
                                            <label>School logo</label>
                                            <LogoContainer>
                                                <Overlay>
                                                    {logoName !== ""
                                                        ? logoName
                                                        : "Upload logo"}
                                                </Overlay>
                                                <LogoInput
                                                    placeholder="Upload document"
                                                    type="file"
                                                    id="schoolLogo"
                                                    name="logo"
                                                    accept="appication/image"
                                                    onChange={(e) => {
                                                        handleLogo(e);
                                                    }}
                                                />
                                                <Upload
                                                    src={
                                                        require("../../../assets/images/landingPage/upload.svg")
                                                            .default
                                                    }
                                                    alt="upload"
                                                />
                                            </LogoContainer>
                                        </SchoolLogo>
                                    </SchoolTopSection>
                                    <SchoolBottomSection>
                                        <ClassSection>
                                            <label>Select class*</label>
                                            <CategorySection>
                                                <ClassSect>
                                                    {classLists.map(
                                                        (element) => (
                                                            <ClassCont
                                                                type={
                                                                    element.total_classes
                                                                }
                                                                key={element.id}
                                                            >
                                                                <span>
                                                                    {
                                                                        element.name
                                                                    }
                                                                </span>
                                                                <TotalClassContainer>
                                                                    {element.campus_classes.map(
                                                                        (
                                                                            cl
                                                                        ) => (
                                                                            <Classes
                                                                                type={
                                                                                    element.total_classes
                                                                                }
                                                                                key={
                                                                                    cl.id
                                                                                }
                                                                            >
                                                                                <Class>
                                                                                    <Check
                                                                                        style={{
                                                                                            border: cl.is_selected
                                                                                                ? "2px solid #56c082"
                                                                                                : "1px solid #d9d9d9",
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            chooseClass(
                                                                                                cl
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        {cl.is_selected ? (
                                                                                            <img
                                                                                                src={
                                                                                                    require("../../../assets/images/landingPage/Tick.svg")
                                                                                                        .default
                                                                                                }
                                                                                                alt="tick"
                                                                                            />
                                                                                        ) : null}
                                                                                    </Check>
                                                                                    {
                                                                                        cl.name
                                                                                    }
                                                                                    <sup>
                                                                                        {cl.name ===
                                                                                        "1"
                                                                                            ? "st"
                                                                                            : cl.name ===
                                                                                              "3"
                                                                                            ? "rd"
                                                                                            : cl.name ===
                                                                                              "2"
                                                                                            ? "nd"
                                                                                            : "th"}
                                                                                    </sup>
                                                                                </Class>
                                                                            </Classes>
                                                                        )
                                                                    )}
                                                                </TotalClassContainer>
                                                            </ClassCont>
                                                        )
                                                    )}
                                                </ClassSect>
                                            </CategorySection>
                                            {isError &&
                                            classIds.length === 0 ? (
                                                <ErrorMsg className="half-section">
                                                    {" "}
                                                    Select class
                                                </ErrorMsg>
                                            ) : null}
                                        </ClassSection>
                                    </SchoolBottomSection>
                                </Cover>
                                <ButtonContainer>
                                    {loading ? (
                                        <ButtonSubmit>
                                            <TefunLoader />
                                        </ButtonSubmit>
                                    ) : (
                                        <Button type="submit" />
                                    )}
                                </ButtonContainer>
                            </Modal>
                        </RightSection>
                    </FormContainer>
                </RegisterFormSection>
            </Container>
        </>
    );
}

export default RegisterForm;

const Container = styled.div`
    padding: 25px 0 25px 0;
`;

const RegisterFormSection = styled.section`
    max-width: 1350px !important;
`;
const TopSection = styled.div`
    top: 0;
    left: 0;
    width: 100%;
`;
const Logo = styled(Link)`
    cursor: pointer;
    width: 100px;
    display: block;
    & img {
        display: block;
        width: 100%;
        @media (max-width: 480px) {
            width: 80%;
        }
    }
`;
const FormContainer = styled.div`
    display: flex;
    padding: 25px 0 0;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 980px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
`;
const LeftSection = styled.div`
    width: 35%;
    margin-right: 50px;
    &.mobile {
        display: none;
        @media all and (max-width: 980px) {
            width: 70%;
            margin: 0 auto;
            display: block;
            margin-bottom: 50px;
            & img {
                display: block;
                width: 100%;
            }
        }
        @media all and (max-width: 480px) {
            width: 90%;
        }
    }
    & img {
        display: block;
        width: 100%;
        min-height: 100%;
        @media all and (max-width: 980px) {
            width: 50%;
            margin: 0 auto;
            display: none;
        }
        @media all and (max-width: 480px) {
            width: 70%;
        }
    }
    @media all and (max-width: 980px) {
        width: 100%;
        margin-right: 0;
    }
`;
const RightSection = styled.div`
    width: 56%;
    @media all and (max-width: 1080px) {
        width: 56%;
    }
    @media all and (max-width: 980px) {
        width: 100%;
        margin-bottom: 20px;
    }
`;

const Modal = styled.form`
    & hr {
        width: 100%;
        border: 1px solid #d9d9d9;
        margin-bottom: 40px;
    }
`;
const Cover = styled.div`
    width: 100%;

    @media all and (max-width: 980px) {
        padding-bottom: 30px;
        height: 100%;
    }
`;
const ErrorMsg = styled.span`
    font-size: 14px;
    width: 80%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    &.half-section {
        position: absolute;
        left: 0;
        color: #f46565;
        bottom: -35px;
    }
    & img {
        margin-right: 5px;
        display: block;
    }
    @media all and (max-width: 480px) {
        width: 80%;
        bottom: -5px;
    }
`;

const Title = styled.h3`
    font-size: 26px;
    font-family: gordita_medium;
    margin-bottom: 10px;
    max-width: 70%;
    margin-bottom: 40px;
    @media all and (max-width: 980px) {
        text-align: center;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    @media all and (max-width: 640px) {
        max-width: 90%;
    }
    @media all and (max-width: 360px) {
        font-size: 16px;
    }
    @media all and (max-width: 480px) {
        font-size: 20px;
    }
`;
const CampusSection = styled.div`
    margin-bottom: 25px;
    position: relative;
    & label {
        display: block;
        color: #aaaaaa;
    }
`;
const Campus = styled.input`
    width: 100%;
    background: #fcfcfc;
    border: 1px solid #d9d9d9;
    padding: 0 25px;
    height: 50px;
    border-radius: 6px;
    font-size: 14px;
    &:focus {
        box-shadow: inset 0 0 0 1px #56c082;
    }
    &.error {
        border: 1px solid red;
    }
`;

const AddressSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    @media all and (max-width: 540px) {
        flex-direction: column;
    }
`;
const Location = styled.div`
    width: 48%;
    position: relative;
    & label {
        display: block;
        color: #aaaaaa;
    }
    @media all and (max-width: 540px) {
        width: 100%;
        margin-bottom: 20px;
    }
`;
const LocationInput = styled.input`
    background: #fcfcfc;
    border: 1px solid #d9d9d9;
    padding: 0 25px;
    height: 50px;
    border-radius: 6px;
    width: 100%;
    font-size: 14px;
    @media all and (max-width: 540px) {
        width: 100%;
    }
    &:focus {
        box-shadow: inset 0 0 0 1px #56c082;
    }
`;
const District = styled.div`
    width: 48%;
    text-align: left;
    position: relative;
    cursor: pointer;
    & label {
        display: block;
        color: #aaaaaa;
    }
    @media all and (max-width: 540px) {
        width: 100%;
    }
`;
const DistrictCover = styled.div`
    position: relative;
`;
const DownArrow = styled.img`
    display: block;
    position: absolute;
    transform: ${({ isCategory }) => isCategory && "rotate(-180deg)"};
    transition: ease 0.4s;
    width: 6%;
    right: 10px;
    top: 45px;
    filter: invert(10%);
    opacity: 0.7;
    @media all and (max-width: 540px) {
        width: 4%;
    }
`;
const LogoContainer = styled.div`
    position: relative;
    overflow: hidden;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
`;
const Overlay = styled.span`
    display: flex;
    color: #828282;
    padding-left: 25px;
    font-size: 14px;
    padding-top: 10px;
    align-items: center;
    position: absolute;
    background: #fcfcfc;
    width: 100%;
    height: 60px;
    right: 1px;
    z-index: 3;
    bottom: 0;
`;
const Upload = styled.img`
    position: absolute;
    display: block;
    right: 10px;
    top: 15px;
    z-index: 5;
`;

const ListName = styled.span`
    display: flex;
    margin-bottom: 5px;
    color: #f3f3f3;
    align-items: flex-start;
    cursor: pointer;
    color: #000;
    font-size: 15px;
    color: #aaaaaa;
    & small {
        margin-right: 5px;
        height: 15px;
        width: 15px;
        background: #fcfcfc;
        border-radius: 5px;
        border: 1px solid #aaaaaa;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        & img {
            display: block;
            width: 90%;
        }
    }
`;
const ListNameSchool = styled.span`
    display: flex;
    background: #fcfcfc;
    margin-bottom: 5px;
    color: #f3f3f3;
    align-items: flex-start;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    color: #000;
    font-size: 15px;
    &:hover {
        background-image: linear-gradient(to right, #63bb4c 0%, #1898af 100%);
        color: #fff;
    }
`;
const PincodeInput = styled.input`
    width: 100%;
    background: #fcfcfc;
    border: 1px solid #d9d9d9;
    padding: 0 25px;
    height: 50px;
    border-radius: 6px;
    color: ${(props) => (props.type == "focus" ? "grey" : "#000")};
    font-size: 14px;

    &:focus {
        box-shadow: inset 0 0 0 1px #56c082;
    }
`;
const DropList = styled.div`
    position: absolute;
    width: 160px;
    height: 150px;
    max-height: 150px;
    overflow-y: scroll;
    padding: 10px 5px;
    z-index: 900;
    left: -110px;
    right: 0;
    top: -151px;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: #fcfcfc;
    border: 1px solid #f3f3f3;
    & h4 {
        font-size: 15px;
        text-align: center;
        margin-bottom: 5px;
        color: #aaaaaa;
    }
    & hr {
        border-bottom: 1px solid #f5f5f5;
        width: 80%;
        margin: 0 auto;
        margin-bottom: 10px;
    }
    @media all and (max-width: 540px) {
        left: 0;
        width: 100%;
    }
`;
const DropListSchool = styled.div`
    position: absolute;
    width: 100%;
    /* height: 120px; */
    max-height: 120px;
    overflow-y: scroll;
    padding: 10px;
    z-index: 900;
    left: 0px;
    right: 0;
    bottom: -120px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: #fcfcfc;
    border: 1px solid #f3f3f3;
    & h4 {
        font-size: 15px;
        text-align: center;
    }
`;
const NameEmail = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media all and (max-width: 540px) {
        flex-wrap: wrap;
    }
`;
const NameContainer = styled.div`
    width: 48%;
    margin-bottom: 25px;
    position: relative;
    & label {
        display: block;
        color: #aaaaaa;
    }
    @media all and (max-width: 540px) {
        width: 100%;
    }
`;
const Name = styled.input`
    width: 100%;
    background: #fcfcfc;
    border: 1px solid #d9d9d9;
    padding: 0 25px;
    height: 50px;
    border-radius: 6px;
    font-size: 14px;
    &:focus {
        box-shadow: inset 0 0 0 1px #56c082;
    }
`;
const ContactSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    @media all and (max-width: 540px) {
        flex-direction: column;
        margin-bottom: 0px;
    }
`;
const Phone = styled.div`
    width: 48%;
    position: relative;
    & label {
        display: block;
        color: #aaaaaa;
    }
    @media all and (max-width: 540px) {
        width: 100%;
        margin-bottom: 20px;
    }
`;
const PhoneInput = styled.input`
    background: #fcfcfc;
    border: 1px solid #d9d9d9;
    padding: 0 25px;
    height: 50px;
    border-radius: 6px;
    width: 100%;
    font-size: 14px;
    @media all and (max-width: 540px) {
        width: 100%;
    }
    &:focus {
        box-shadow: inset 0 0 0 1px #56c082;
    }
`;
const Email = styled.div`
    width: 48%;
    position: relative;
    text-align: left;
    & label {
        display: block;
        color: #aaaaaa;
    }
    @media all and (max-width: 980px) {
        margin-bottom: 25px;
    }
    @media all and (max-width: 540px) {
        width: 100%;
    }
`;
const EmailInput = styled.input`
    width: 100%;
    background: #fcfcfc;
    border: 1px solid #d9d9d9;
    padding: 0 25px;
    height: 50px;
    border-radius: 6px;
    font-size: 14px;
    &:focus {
        box-shadow: inset 0 0 0 1px #56c082;
    }
`;
const ButtonContainer = styled.div`
    width: 100%;
    padding-top: 20px;
    @media all and (max-width: 980px) {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 25;
    }
`;
const Button = styled.input`
    width: 100%;
    height: 50px;
    display: flex;
    cursor: pointer;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    font-family: gordita_medium;
    background-image: linear-gradient(to right, #63bb4c 0%, #1898af 100%);
    color: #fff;
    border-radius: 5px;
    @media all and (max-width: 980px) {
        border-radius: 0px;
    }
`;
const ButtonSubmit = styled.span`
    width: 100%;
    height: 50px;
    display: flex;
    cursor: pointer;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    font-family: gordita_medium;
    background-image: linear-gradient(to right, #63bb4c 0%, #1898af 100%);
    color: #fff;
    border-radius: 5px;
`;

const SchoolTopSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    @media all and (max-width: 540px) {
        flex-direction: column;
        margin-bottom: 0;
    }
`;
const SchoolCategory = styled.div`
    width: 48%;
    position: relative;
    & label {
        display: block;
        color: #aaaaaa;
    }
    @media all and (max-width: 540px) {
        width: 100%;
        margin-bottom: 20px;
    }
`;
const CategoryInput = styled.input`
    width: 100%;
    background: #fcfcfc;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    padding: 0 25px;
    height: 50px;
    position: relative;
    caret-color: transparent;
    font-size: 14px;
    border-radius: 6px;
    color: ${(props) => (props.type == "focus" ? "grey" : "#000")};
    font-size: 14px;

    @media all and (max-width: 540px) {
        width: 100%;
    }
`;
const ClassSection = styled.div`
    width: 100%;
    position: relative;
    & label {
        display: block;
        color: #aaaaaa;
        margin-bottom: 5px;
    }
    @media all and (max-width: 540px) {
        width: 100%;
        margin-bottom: 20px;
    }
`;
const ClassInputContiner = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 540px) {
        flex-wrap: wrap;
    }
`;
const MainContainer = styled.div`
    width: 32%;
    cursor: pointer;
    border-radius: 6px;
    position: relative;

    @media all and (max-width: 540px) {
        width: 48%;
        margin-bottom: 20px;
    }
`;
const ClassInput = styled.div`
    cursor: ${(props) => (props.category === "" ? "not-allowed" : "allowed")};
    background: #fcfcfc;
    padding: 3px 10px;
    height: 50px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    &.focused {
        border: 1px solid #56c082;
    }
`;
const InputTop = styled.div`
    display: flex;
    align-items: center;
`;
const Check = styled.small`
    width: 25px;
    height: 25px;
    border-radius: 3px;
    margin-right: 5px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &img {
        display: block;
        width: 100%;
    }
`;
const Cls = styled.span`
    font-size: 13px;
`;
const InputBottom = styled.div`
    position: relative;
    &::after {
        content: "";
        width: 100%;
        height: 2px;
        border-bottom: 1px dotted #d9d9d9;
        position: absolute;
        bottom: 3px;
        left: 0;
    }
`;

const SchoolBottomSection = styled(SchoolTopSection)``;
const StudentCount = styled.div`
    width: 48%;
    position: relative;
    & label {
        display: block;
        color: #aaaaaa;
    }

    @media all and (max-width: 540px) {
        width: 100%;
        margin-bottom: 20px;
    }
`;
const CountInput = styled.input`
    background: #fcfcfc;
    border: 1px solid #d9d9d9;
    padding: 0 25px;
    height: 50px;
    border-radius: 6px;
    width: 100%;
    font-size: 14px;
    &:focus {
        box-shadow: inset 0 0 0 1px #56c082;
    }
`;
const SchoolLogo = styled(SchoolCategory)``;
const LogoInput = styled(CountInput)`
    border: none;
    background-color: transparent;
    position: relative;
    font-size: 14px;
    &::file-selector-button {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
        z-index: 99;
    }
`;

const CategorySection = styled.div`
    width: 100%;
`;
const ClassSect = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    & span {
        color: #2d2d2d;
    }
`;
const Classes = styled.div`
    width: ${(props) =>
        props.type === 4
            ? "20%"
            : props.type === 3
            ? "25%"
            : props.type === 2
            ? "45%"
            : null};
    @media all and (max-width: 1080px) {
        width: ${(props) =>
            props.type === 4
                ? "22%"
                : props.type === 3
                ? "22%"
                : props.type === 2
                ? "22%"
                : null};
    } ;
`;
const Class = styled.div`
    display: flex;
    align-items: flex-start;
    font-size: 20px;
    & sup {
        font-size: 11px;
    }
`;
const ClassCont = styled.div`
    width: ${(props) =>
        props.type === 4
            ? "49%"
            : props.type === 3
            ? "45%"
            : props.type === 2
            ? "26%"
            : null};
    margin-bottom: 16px;
    color: #737373;
    @media all and (max-width: 1080px) {
        width: 100%;
    }

    & span {
        color: #2d2d2d;
        opacity: 65%;
        font-size: 15px;
    }
    &:last-child {
        margin-bottom: 0;
    }
`;
const TotalClassContainer = styled.div`
    width: 100%;
    margin-top: 10px;
    display: flex;
`;
