import React, { useContext, useState } from "react";
import styled from "styled-components";
import TermsService from "../modals/general/TermsService";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import FlagDropDown from "./general/FlagDropDown";
import CountrySelector from "./general/CountrySelector";
import { accountsConfig } from "../../../helpers/axiosConfig";
import TefunLoader from "../../../tefun/TefunLoader";
import { Context } from "../../../context/store";

function ResetOne() {
    const location = useLocation();
    const {
        state: {
            user_validate: { selected_country, phone: store_phone },
        },
        dispatch,
    } = useContext(Context);

    const [selectedCountry, setSelectedCountry] = useState("");
    const [phone, setPhone] = useState(store_phone ? store_phone : "");
    const [countryselector, setCountryselector] = useState(false);
    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const handleShow = () => {
        setCountryselector((prevValue) => !prevValue);
    };

    //Preventing "Enter" key function
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            verifyService();
        } else if (e.keyCode === 69) {
            e.preventDefault();
        }
    };

    //Veryfing "learn" service
    const verifyService = (e) => {
        if (e) {
            e.preventDefault();
        }
        if (phone) {
            setLoading(true);
            //Service is passed to the url
            accountsConfig
                .post("/api/v1/accounts/forget/password/enter/phone/", {
                    country: selected_country?.web_code,
                    service: "learn",
                    phone: phone,
                })

                .then((response) => {
                    const {
                        StatusCode,
                        data: { message },
                    } = response.data;
                    if (StatusCode === 6000) {
                        dispatch({
                            type: "USER_CHECK_VALIDATE",
                            user_validate: { phone: phone },
                        });
                        setSearchParams({
                            action: "forgot-password-verify-otp",
                        });
                    } else if (StatusCode === 6001) {
                        setLoading(false);
                        setError(true);
                        setErrorMessage(message);
                        if (message === "User Not Exists") {
                            setSearchParams({ action: "phone" });
                        }
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    setError(true);
                    setErrorMessage(
                        "An error occurred, please try again later"
                    );
                });
        } else {
            setError(true);
            setErrorMessage("This field cannot be left blank");
        }
    };

    //Validating the function of entering the phone number
    const onChange = (e) => {
        setError(false);
        const re = /^[0-9\b]+$/;
        if (e.target.value === "" || re.test(e.target.value)) {
            setPhone(e.target.value);
        }
    };

    const onSelectHandler = (selected) => {
        setSelectedCountry(selected);

        dispatch({
            type: "USER_CHECK_VALIDATE",
            user_validate: {
                selected_country: selected,
            },
        });
    };

    return (
        <Container>
            <JoinNow>
                <ItemContainer>
                    <>
                        <CountrySelector
                            show={countryselector}
                            handleClick={handleShow}
                            onSelectHandler={onSelectHandler}
                            selectedCountry={selectedCountry}
                            selected_country={selected_country}
                        />
                        <Content>
                            <Title className="g-medium">
                                Password Reset Setup (1/3)
                            </Title>
                            <Description className="g-regular">
                                Enter your registered mobile number to reset
                                your password
                            </Description>
                            <MiddleContainer>
                                <>
                                    <FlagDropDown handleClick={handleShow} />
                                    <InputContainer
                                        className="b-medium"
                                        style={{
                                            borderColor: error
                                                ? "#f32e2f"
                                                : "#2f3337",
                                        }}
                                    >
                                        <Icon
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/auth/phone.svg"
                                            alt="Icon"
                                            onClick={() => setError(!error)}
                                        />
                                        {selected_country?.phone_code}
                                        <InputField
                                            autoFocus
                                            className="b-medium"
                                            placeholder="Enter your phone number"
                                            onChange={onChange}
                                            value={phone}
                                            onKeyDown={handleKeyDown}
                                        />
                                        {error && (
                                            <ErrorText className="g-medium">
                                                {error_message}
                                            </ErrorText>
                                        )}
                                    </InputContainer>
                                </>
                            </MiddleContainer>

                            <BottomButton
                                onClick={verifyService}
                                className="g-medium white"
                            >
                                {isLoading ? <TefunLoader /> : "Send OTP"}
                            </BottomButton>
                            <BottomRow className="g-medium">
                                Do you want to login?
                                <RowItem
                                    to={`${location.pathname}?action=login`}
                                    className="g-medium white"
                                >
                                    Login
                                </RowItem>
                            </BottomRow>
                        </Content>
                        <TermsService />
                    </>
                </ItemContainer>
            </JoinNow>
        </Container>
    );
}

export default ResetOne;

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 500;
`;
const CloseIcon = styled.span`
    font-size: 34px;
    color: #ff9800;
    position: absolute;
    left: -42px;
    top: 11px;
    cursor: pointer;
    @media (max-width: 480px) {
        font-size: 30px;
        left: -38px;
    }
    @media (max-width: 400px) {
        left: 9px;
        top: 11px;
        z-index: 500;
    }
`;
const JoinNow = styled.div`
    z-index: 500;
    background: #fff;
    width: 600px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    animation: slide-box 0.4s ease;
    @keyframes slide-box {
        0% {
            right: -600px;
        }
        100% {
            right: 0;
        }
    }
    @media (max-width: 640px) {
        width: 428px;
    }
    @media (max-width: 480px) {
        width: 359px;
    }
    @media (max-width: 400px) {
        width: 100%;
    }
`;
const ItemContainer = styled.div`
    background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/auth/decorator.svg");
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
    background-position-y: -106px;
    @media (max-width: 980px) {
        background-position-y: -106px;
    }
    @media (max-width: 640px) {
        padding: 0 29px;
        background-position-y: -79px;
    }
    @media (max-width: 380px) {
        padding: 0 22px;
        background-position-y: -79px;
    }
`;
const Content = styled.div`
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 640px) {
        padding-top: 52px;
    }
`;
const Title = styled.h4`
    font-size: 23px;
    line-height: 1.4em;
    margin-top: 14px;
    @media (max-width: 640px) {
        font-size: 23px;
        margin-top: 0;
    }
    @media (max-width: 480px) {
        font-size: 20px;
    }
`;
const Description = styled.p`
    margin: 18px 0 40px;
    width: 94%;
    font-size: 14px;
    @media (max-width: 480px) {
        width: 100%;
        font-size: 13px;
    }
`;
const InputContainer = styled.div`
    font-family: "gordita_medium";
    position: relative;
    border: 1px solid;
    border-radius: 7px;
    padding: 15px 18px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #000;
    font-size: 17px;
    margin-left: 10px;
    width: -webkit-fill-available;
    width: -moz-available;
    @media (max-width: 640px) {
        padding: 13px 13px;
    }
    @media (max-width: 480px) {
        padding: 8px 8px;
        border-color: #5f6367;
        font-size: 15px;
    }
    @media (max-width: 380px) {
        font-size: 14px;
        min-height: 40px;
    }
    &:focus-within {
        border-color: #5cc66a;
    }
`;
const Icon = styled.img`
    max-width: 17px;
    max-height: 17px;
    margin-right: 12px;
    display: block;
    @media (max-width: 480px) {
        display: none;
    }
`;
const InputField = styled.input`
    font-family: "gordita_medium";
    width: 81%;
    color: #000;
    font-size: 17px;
    padding-left: 5px;
    caret-color: #5cc66a;
    @media (max-width: 640px) {
        width: 70%;
        ::placeholder {
            font-size: 14px;
        }
    }
    @media (max-width: 480px) {
        width: 80%;
        // padding-left: 10.66667px;
        font-size: 16px;
        ::placeholder {
            font-size: 12px;
        }

        // transform: scale(0.9375);
        // transform-origin: left top;
    }
    @media (max-width: 380px) {
        font-size: 14px;
    }
`;
const BottomRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 14px;
    font-size: 14px;
    @media (max-width: 640px) {
        margin-top: 20px;
    }
    @media (max-width: 480px) {
        font-size: 13px;
    }
`;
const RowItem = styled(Link)`
    color: #5cc66a;
    font-size: 14px;
    margin-left: 7px;
    @media (max-width: 480px) {
        font-size: 13px;
    }
`;
const LoaderContainer = styled.div`
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;
const MiddleContainer = styled.div`
    display: flex;
    position: relative;
`;
const ErrorText = styled.span`
    font-size: 13px;
    position: absolute;
    left: 0;
    color: #f46565;
    bottom: -27px;
    @media (max-width: 480px) {
        font-size: 12px;
        bottom: -26px;
    }
`;
const BottomButton = styled.div`
    cursor: pointer;
    background: linear-gradient(
        272deg,
        rgba(34, 193, 195, 1) 0%,
        rgba(99, 187, 76, 1) 0%,
        rgba(24, 152, 175, 1) 100%
    );
    display: block;
    border-radius: 6px;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    color: #fff;
    min-height: 50px;
    @media (max-width: 640px) {
        height: 50px;
    }
    @media (max-width: 480px) {
        font-size: 15px;
        height: 44px;
    }
`;
