import React, { Suspense, useState, useContext, useEffect } from "react";
import AppRouter from "./AppRouter";
import { Route, Routes } from "react-router-dom";
import { Context } from "../../context/store";
import ModalCover from "../../../general/login/ModalCover";
import { accountsConfig } from "../../helpers/axiosConfig";
import PageLoader from "../../includes/PageLoader";

function MainRouter() {
    const {
        dispatch,
        state: { user_data },
    } = useContext(Context);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        function fetchUserProfile() {
            accountsConfig
                .get("/api/v1/accounts/profile/?response_type=minimal", {
                    headers: {
                        Authorization: `Bearer ${user_data.access_token}`,
                    },
                })
                .then(function (response) {
                    const { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        dispatch({ type: "UPDATE_USER_DATA", payload: data });
                        dispatch({
                            type: "UPDATE_CAMPUS",
                            payload: data.campus_data,
                        });
                    }
                });
        }
        if (user_data.access_token) fetchUserProfile();
    }, [user_data.is_verified]);

    useEffect(() => {
        async function fetchUserData() {
            let promise = new Promise((resolve, reject) => {
                let user_data = localStorage.getItem("user_data");
                user_data = JSON.parse(user_data);
                dispatch({ type: "UPDATE_USER_DATA", payload: user_data });
                setTimeout(() => {
                    resolve("done!");
                    setLoading(false);
                }, 500);
            });

            let result = await promise;
        }
        fetchUserData();

        async function fetchUserValidate() {
            let promise = new Promise((resolve, reject) => {
                let user_validate = localStorage.getItem("user_validate");
                user_validate = JSON.parse(user_validate);
                dispatch({
                    type: "USER_CHECK_VALIDATE",
                    user_validate: user_validate,
                });
                setTimeout(() => {
                    resolve("done!");
                    setLoading(false);
                }, 500);
            });

            let result = await promise;
        }
        fetchUserValidate();
    }, []);

    // const { state, dispatch } = useContext(Context);

    useEffect(() => {
        const current_topic = localStorage.getItem("current_topic");
        // console.log(current_topic, "hello world");
        if (current_topic) {
            dispatch({
                type: "UPDATE_CURRENT_VIDEO",
                currentTopic: current_topic,
            });
        }
    }, []);

    return !isLoading ? (
        <>
            <Suspense fallback={<PageLoader />}>
                {/* <ModalCover /> */}
                <Routes>
                    <Route path="/*" element={<AppRouter />} />
                </Routes>
            </Suspense>
        </>
    ) : (
        <PageLoader />
    );
}

export default MainRouter;
