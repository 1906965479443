import React from "react";
import styled from "styled-components";

function NewSpotlight() {
    return (
        <SpotSection>
            <SpotBg>
                <SpotDetails className="details">
                    <LogoContainer>
                        <Tefun>
                            <img
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/01-06-2024/yiaai.svg"
                                alt="Yiaai"
                            />
                        </Tefun>
                        <Talrop target="_blank" href="https://talrop.com/">
                            <img
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/01-06-2024/talrop.svg"
                                alt="Talrop"
                            />
                        </Talrop>
                    </LogoContainer>
                    <h3>
                        Unlock Free Tech Education with Talrop's{" "}
                        <span>OTT Platform</span>
                    </h3>
                    <p>
                        Yiaai is an OTT platform working to educate, inform, and
                        uplift the community by offering free courses in
                        different areas of interest. Its mission is to
                        democratise the access to knowledge and the space to
                        learn.
                    </p>
                    <BottomContainer>
                        <StoreContent>
                            <BgConatiner>
                                <DownloadText>
                                    <img
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/01-06-2024/green-ellipse.svg"
                                        alt="Green-round"
                                    />
                                    Download the app
                                </DownloadText>
                                <Store>
                                    <App
                                        target="_blank"
                                        href="https://apps.apple.com/in/app/yiaai-ott-community-platform/id1609634713"
                                    >
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/01-06-2024/app-store.svg"
                                            alt="Apple-store"
                                        />
                                    </App>

                                    <App
                                        target="_blank"
                                        href="https://play.google.com/store/apps/details?id=com.yiaai"
                                    >
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/01-06-2024/play-store.svg"
                                            alt="Android-store"
                                        />
                                    </App>
                                </Store>
                                <Ellipse
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/01-06-2024/round-green-1.svg"
                                    alt="Green-round"
                                />
                            </BgConatiner>
                        </StoreContent>
                        <ArrowContainer>
                            <img
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/01-06-2024/down-arrow.svg"
                                alt="Arrow"
                            />
                        </ArrowContainer>
                    </BottomContainer>
                </SpotDetails>
                <SpotImgContainer>
                    <img
                        className="hero"
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/03-06-2024/yiaai.png"
                        alt="Spotlight"
                    />
                </SpotImgContainer>
            </SpotBg>
        </SpotSection>
    );
}

export default NewSpotlight;

const SpotSection = styled.section`
    background-color: #f3f9eb;
    background-image: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/tefun/28-01-2022/lines-bg.svg");
    background-position: top 0 left 10%;
    background-repeat: no-repeat;
    background-size: 1000px;
    padding: 100px 0px;
    @media (max-width: 980px) {
        padding-bottom: 50px;
        padding: 8% 0px;
    }
    @media (max-width: 480px) {
        padding: 8% 0px;
    }
`;

const SpotBg = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    max-width: 1280px;
    @media (max-width: 1080px) {
        flex-wrap: wrap;
        flex-direction: column;
        gap: 20px;
    }
    @media (max-width: 640px) {
        border-radius: 15px;
    }
    @media (max-width: 480px) {
        gap: 10px;
    }
`;

const SpotDetails = styled.div`
    width: 48%;
    position: relative;
    @media (max-width: 640px) {
        text-align: center;
    }
    @media (max-width: 1080px) {
        width: 100%;
        margin-bottom: 50px;
    }
    & h4 {
        font-size: 24px;
        font-family: "gordita_medium";
        margin-bottom: 20px;
        @media (max-width: 640px) {
            width: 100%;
            text-align: center;
            font-size: 22px;
        }
        @media (max-width: 480px) {
            font-size: 15px;
            margin-bottom: 12px;
        }
    }
    & h3 {
        font-size: 45px;
        font-family: "gordita_bold";
        max-width: 100%;
        margin-bottom: 30px;
        @media (max-width: 1280px) {
            font-size: 40px;
        }
        @media (max-width: 768px) {
            font-size: 38px;
        }
        @media (max-width: 640px) {
            font-size: 35px;
        }
        @media (max-width: 480px) {
            font-size: 28px;
            margin-bottom: 20px;
        }
        & span {
            font-size: 45px;
            font-family: "gordita_bold";
            color: #0fa76f;
            @media (max-width: 1480px) {
                font-size: 50px;
            }
            @media (max-width: 1280px) {
                font-size: 45px;
            }
            @media (max-width: 768px) {
                font-size: 40px;
            }
            @media (max-width: 640px) {
                font-size: 35px;
            }
            @media (max-width: 480px) {
                font-size: 28px;
            }
        }
    }
    & p {
        font-size: 18px;
        font-family: "gordita_regular";
        color: #545454;
        width: 90%;
        line-height: 30px;
        margin-bottom: 40px;
        @media (max-width: 768px) {
            width: 100%;
            line-height: 26px;
        }
        @media (max-width: 640px) {
            font-size: 18px;
        }
        @media (max-width: 480px) {
            font-size: 16px;
            margin-bottom: 20px;
        }
    }
`;

const ArrowContainer = styled.div`
    display: block;
    width: 15%;
    z-index: 3;
    @media (max-width: 980px) {
        width: 10%;
    }
    @media (max-width: 768px) {
        width: 15%;
    }
    @media (max-width: 520px) {
        display: none;
        margin-bottom: 18px;
    }
    & img {
        width: 100%;
        display: block;
    }
`;

const LogoContainer = styled.div`
    display: flex;
    background-color: #fff;
    width: fit-content;
    align-items: center;
    padding: 10px 14px;
    padding-right: 0;
    /* gap: 20px; */
    margin-bottom: 20px;
    border-radius: 8px;
    @media (max-width: 640px) {
        margin: 0 auto;
        margin-bottom: 20px;
    }
`;

const Tefun = styled.div`
    width: 100px;
    display: flex;
    align-items: center;
    border-right: 1px solid #d0d0d0;
    padding-right: 20px;
    @media (max-width: 640px) {
        width: 70px;
    }
    & img {
        width: 100%;
        display: block;
    }
`;

const Talrop = styled.a`
    width: 80px;
    display: flex;
    align-items: center;
    @media (max-width: 640px) {
        width: 70px;
    }
    & img {
        width: 100%;
        display: block;
    }
`;

const SpotImgContainer = styled.div`
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    & img.hero {
        display: block;
        width: 100%;
        border-radius: 30px;
    }
    @media (max-width: 1080px) {
        width: 70%;
        margin: 0 auto;
        align-items: center;
    }
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const StoreContent = styled.div`
    border: 1px solid #91c74680;
    background: #f8ffee;
    border-radius: 16px;
    align-items: center;
    & small {
        font-size: 20px;
        font-family: "gordita_medium";
        color: #0fa76f;
        margin-right: 20px;
        @media (max-width: 480px) {
            margin-right: 10px;
            font-size: 18px;
        }
    }
`;

const Store = styled.div`
    display: flex;
    width: 100%;
`;

const App = styled.a`
    margin-right: 10px;
    width: 170px;
    display: block;
    @media (max-width: 640px) {
        width: 100%;
    }
    & img {
        display: block;
        width: 100%;
        @media (max-width: 640px) {
            width: 90%;
            margin: 0 auto;
        }
    }
    &:last-child {
        margin-right: 0;
    }
`;

const Ellipse = styled.img`
    width: 20px;
    position: absolute;
    right: 80px;
    bottom: 30px;
    @media (max-width: 980px) {
        width: 15px;
    }
    @media (max-width: 480px) {
        display: none;
    }
`;

const DownloadText = styled.h5`
    font-size: 22px;
    font-family: "gordita_regular";
    color: #003c3c;
    margin-bottom: 24px;
    position: relative;
    & img {
        position: absolute;
        left: 23%;
        top: 10px;
        @media (max-width: 640px) {
            display: none;
        }
    }
    @media (max-width: 980px) {
        font-size: 18px;
        margin-bottom: 18px;
    }
    @media (max-width: 480px) {
        font-size: 16px;
        margin-bottom: 10px;
    }
`;

const BottomContainer = styled.div`
    display: flex;
    gap: 60px;
    @media (max-width: 768px) {
        gap: 30px;
    }
`;

const BgConatiner = styled.div`
    background: url(${"https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/01-06-2024/round-green-1.svg"});
    background-position: 0px 50px;
    background-repeat: no-repeat;
    background-size: 70px;
    z-index: 10;
    position: relative;
    padding: 24px 22px;
    border-radius: 16px;
    @media (max-width: 1080px) {
        padding: 18px;
    }
    @media (max-width: 480px) {
        padding: 10px;
    }
`;
