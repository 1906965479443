import React, { useContext, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Context } from "../../components/context/store";
import InitialLogin from "./InitialLogin";
import LoginEnterPassword from "./LoginEnterPassword";
import LoginPage from "./LoginPage";
import LoginWithOtp from "./LoginWithOtp";
import OtpCard from "./OtpCard";
import ResetPassword from "./ResetPassword";
import SighnupName from "./SighupName";
import LoginModal from "../../components/includes/login/modals/LoginModal";
import PasswordModal from "../../components/includes/login/modals/PasswordModal";
import LoginWithOTPModal from "../../components/includes/login/modals/LoginWithOTPModal";
import ResetOne from "../../components/includes/login/modals/ResetOne";
import EnterPhoneModal from "../../components/includes/login/modals/EnterPhoneModal";
import EnterOTPModal from "../../components/includes/login/modals/EnterOTPModal";
import EnterNameModal from "../../components/includes/login/modals/EnterNameModal";
import SetPasswordModal from "../../components/includes/login/modals/SetPasswordModal";
import ResetTwo from "../../components/includes/login/modals/ResetTwo";
import ResetThree from "../../components/includes/login/modals/ResetThree";

const ModalCover = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const {
        state: {
            user_validate: { phone },
        },
        dispatch,
    } = useContext(Context);
    const action = searchParams.get("action");
    const [ismodal, setIsmodal] = useState(false);

    useEffect(() => {
        if (!phone) {
            if (
                action === "password" ||
                action === "verify" ||
                action === "password" ||
                action === "verify-otp" ||
                action === "forgot-password" ||
                action === "forgot-password-verify-otp" ||
                action === "forgot-password-reset"
            )
                setSearchParams({ action: "login" });
        }

        if (
            action === "login" ||
            action === "verify" ||
            action === "name" ||
            action === "set-password" ||
            action === "verify-otp" ||
            action === "phone" ||
            action === "password" ||
            action === "forgot-password" ||
            action === "forgot-password-verify-otp" ||
            action === "forgot-password-reset"
        ) {
            setIsmodal(true);
        } else {
            setIsmodal(false);
        }
    }, [action]);

    return (
        <Content ismodal={ismodal}>
            <>
                {action === "login" ? (
                    <LoginModal />
                ) : action === "verify-otp" ? (
                    <EnterOTPModal />
                ) : action === "name" ? (
                    <EnterNameModal />
                ) : action === "set-password" ? (
                    <SetPasswordModal />
                ) : action === "password" ? (
                    <PasswordModal />
                ) : action === "phone" ? (
                    <EnterPhoneModal />
                ) : action === "verify" ? (
                    <LoginWithOTPModal />
                ) : action === "forgot-password" ? (
                    <ResetOne />
                ) : action === "forgot-password-verify-otp" ? (
                    <ResetTwo />
                ) : action === "forgot-password-reset" ? (
                    <ResetThree />
                ) : null}
                <CloseButton
                    onClick={() => {
                        setIsmodal(false);
                        setSearchParams();
                    }}
                >
                    <img
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/29-09-2021/close.svg"
                        alt="icon"
                    />
                </CloseButton>
            </>
        </Content>
    );
};

export default ModalCover;

const Content = styled.div`
    width: ${({ ismodal }) => (ismodal ? "600px" : "0")};
    opacity: ${({ ismodal }) => (ismodal ? "1" : "0")};
    height: 100vh;
    background: #fff;
    transition: 0.4s ease;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 200;
    @media (max-width: 640px) {
        width: ${({ ismodal }) => (ismodal ? "428px" : "0")};
    }
    @media (max-width: 480px) {
        width: ${({ ismodal }) => (ismodal ? "359px" : "0")};
    }
    @media (max-width: 400px) {
        width: ${({ ismodal }) => (ismodal ? "100%" : "0")};
    }
`;
const CloseButton = styled.div`
    border-radius: 50%;
    padding: 6px;
    background: #fff;
    width: 23px;
    height: 23px;
    position: absolute;
    top: 4px;
    left: -28px;
    cursor: pointer;
    z-index: 501;
    @media (max-width: 400px) {
        left: 6px;
        border: 1px solid #4caf50;
        background: transparent;
    }
    @media (max-width: 360px) {
        left: 6px;
    }
    img {
        width: 100%;
        display: block;
    }
`;
