import React, { useContext } from "react";
import styled from "styled-components";
import { Link, useSearchParams } from "react-router-dom";
import { Context } from "../../context/store";

function SchoolJoin() {
    const [searchParams, setSearchParams] = useSearchParams();
    const {
        state: { user_data },
    } = useContext(Context);

    return (
        <>
            <Joinsec>
                <JoinContainerSection className="wrapper">
                    <SecTop>
                        <h3>
                            <span>സ്കൂളുകൾക്ക്</span>&nbsp; Tefun
                        </h3>
                        <span style={{ display: "inline" }}>
                            പ്രോഗ്രാമിന്റെ ഭാഗമാകാം
                        </span>
                    </SecTop>
                    <SecBottom>
                        <JoinContainer className="container">
                            <Contents>
                                <Boxes className="box" color="red">
                                    <Circle color="red">
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/04-02-2022/phone-g-call.svg"
                                            alt="coding"
                                        />
                                    </Circle>
                                </Boxes>
                                <Content>
                                    <b>Tefun</b> പ്രോഗ്രാമിന്റെ ഭാഗമാകാൻ
                                    ആഗ്രഹിക്കുന്ന സ്കൂളുകൾക്ക് <b>Talrop</b>
                                    -ന്റെ ടീമുമായി
                                    <a
                                        href="https://wa.me/+918589998744"
                                        target="_blank"
                                    >
                                        <nobr> 858 999 8744</nobr>
                                    </a>{" "}
                                    എന്ന നമ്പറിൽ കോൺടാക്ട് ചെയ്യാവുന്നതാണ്.
                                </Content>
                            </Contents>
                            <Contents>
                                <Boxes className="box" color="yellow">
                                    <Circle color="yellow">
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/tefun/28-01-2022/scissors.svg"
                                            alt="coding"
                                        />
                                    </Circle>
                                </Boxes>
                                <Content>
                                    സ്കൂളിന്റെ ലോഗോയും അവശ്യ വിവരങ്ങളും നൽകുക.
                                </Content>
                            </Contents>
                            <Contents>
                                <Boxes className="box" color="green">
                                    <Circle color="green">
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/04-02-2022/certificate-r.svg"
                                            alt="coding"
                                        />
                                    </Circle>
                                </Boxes>
                                <Content>
                                    സ്കൂൾ നിശ്ചയിക്കുന്ന ഉദ്ഘാടന ദിനത്തിൽ
                                    ആവശ്യമായ Inauguration Video ഡിജിറ്റലായി{" "}
                                    <b>Talrop</b> നൽകുന്നതാണ്.
                                </Content>
                            </Contents>
                            <Contents>
                                <Boxes className="box" color="violet">
                                    <Circle color="violet">
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/05-02-2022/devices.svg"
                                            alt="coding"
                                        />
                                    </Circle>
                                </Boxes>
                                <Content>
                                    Mobile, Tab, Laptop, Desktop Computer
                                    എന്നിവയിൽ ഏതെങ്കിലും ഡിവൈസുകൾ ഉപയോഗിച്ച്
                                    Talrop ലഭ്യമാക്കുന്ന Web, Android, iOS
                                    പ്ലാറ്റ്ഫോമുകളിലൂടെ കുട്ടികൾക്ക് പഠിക്കുവാൻ
                                    സാധിക്കും.
                                </Content>
                            </Contents>
                            <Contents>
                                <Boxes className="box" color="blue">
                                    <Circle color="blue">
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/05-02-2022/certificate.svg"
                                            alt="coding"
                                        />
                                    </Circle>
                                </Boxes>
                                <Content>
                                    വിജയകരമായി പൂർത്തീകരിക്കുന്ന
                                    വിദ്യാർത്ഥികൾക്ക് ടാൽറോപ് സർട്ടിഫിക്കറ്റ്
                                    നൽകുന്നു.
                                </Content>
                            </Contents>
                        </JoinContainer>
                        {/* {user_data.is_verified ? (
							<SubscribeBtn to="/tefun">
								Continue Learning
							</SubscribeBtn>
						) : (
							<SubscribeBtn
								to="?action=login"
								onClick={(e) => {
									e.preventDefault();
									setSearchParams({ action: "login" });
								}}
							>
								Join for free
							</SubscribeBtn>
						)} */}
                    </SecBottom>
                </JoinContainerSection>
            </Joinsec>
        </>
    );
}

export default SchoolJoin;

const Joinsec = styled.section`
    padding: 50px 0;
    @media all and (max-width: 640px) {
        padding: 50px 0 0;
    }
`;
const JoinContainerSection = styled.div``;
const SecTop = styled.div`
    text-align: center;
    margin-bottom: 80px;
    & span {
        color: #0fa76f;
        font-size: 40px;
        display: inline;
        font-family: EGGIndulekhaUni;
        @media all and (max-width: 1280px) {
            font-size: 30px;
        }
        @media all and (max-width: 640px) {
            font-size: 28px;
        }
        @media all and (max-width: 360px) {
            font-size: 24px;
        }
    }
    & h3 {
        font-size: 40px;
        /* font-family: EGGIndulekhaUni; */
        font-family: "gordita_medium";
        @media all and (max-width: 1280px) {
            font-size: 30px;
        }
        @media all and (max-width: 640px) {
            font-size: 28px;
        }
        @media all and (max-width: 360px) {
            font-size: 24px;
        }
    }
    @media all and (max-width: 640px) {
        margin-bottom: 50px;
    }
    @media all and (max-width: 480px) {
        margin-bottom: 40px;
    }
`;
const SecBottom = styled.div`
    width: 100%;
    text-align: center;
    height: 100%;
`;
const JoinContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* grid-gap: px; */
    flex-wrap: wrap;
    margin: 0 auto;
    position: relative;
    grid-gap: 30px;
    margin-bottom: 35px;
    @media all and (max-width: 768px) {
        /* flex-direction: column; */
        align-items: flex-start;
        grid-gap: 20px;
    }
    @media all and (max-width: 640px) {
        margin-bottom: 25px;
    }
`;
const Boxes = styled.div`
    width: 150px;
    height: 150px;
    margin: 0 auto;
    margin-bottom: 50px;
    border-radius: 30px;
    background: ${(props) =>
        props.color === "red"
            ? "#009A66 !important"
            : props.color === "yellow"
            ? "#FBB82C !important"
            : props.color === "green"
            ? "#FF5B2F !important"
            : props.color === "violet"
            ? "#B257FF !important"
            : props.color === "blue"
            ? "#4C9AFE !important"
            : null};
    transform: rotate(45deg);
    position: relative;
    &:last-child {
        margin-right: 0;
    }
    @media all and (max-width: 1080px) {
        width: 100px;
        height: 100px;
        margin-bottom: 20px;
    }
    @media all and (max-width: 480px) {
        width: 80px;
        height: 80px;
    }
`;
const Circle = styled.span`
    position: absolute;
    bottom: 25%;
    right: -20%;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    background-color: #fff;
    border: ${(props) =>
        props.color === "red"
            ? "2px solid #009A66 !important"
            : props.color === "yellow"
            ? "2px solid #FBB82C !important"
            : props.color === "green"
            ? "2px solid #FF5B2F !important"
            : props.color === "violet"
            ? "2px solid #B257FF !important"
            : props.color === "blue"
            ? "2px solid #4C9AFE !important"
            : null};
    & img {
        display: block;
        width: 40%;
        transform: rotate(-45deg);
        @media all and (max-width: 640px) {
            width: 50%;
        }
    }
    @media all and (max-width: 1080px) {
        width: 50px;
        height: 50px;
    }
    @media all and (max-width: 480px) {
        width: 40px;
        height: 40px;
    }
`;
const Contents = styled.div`
    width: 30%;
    position: relative;
    margin-bottom: 30px;
    text-align: center;
    @media all and (max-width: 980px) {
        width: 48%;
        &:last-child {
            margin-bottom: 0;
        }
    }
    @media all and (max-width: 768px) {
        width: 47%;
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    /* @media all and (max-width: 640px) {
            width: 70%;
            margin-bottom: 40px;
            &:last-child{
                margin-bottom: 0;
            }
        } */
    @media all and (max-width: 480px) {
        width: 85%;
    }
    /* &:nth-child(2)::before{
        content: "";
        width: 65%;
        position: absolute;
        border-top: 2px dashed #1F9BA5;;
        top: 75px;
        left: -20%;
        z-index: -1;
        @media all and (max-width: 1080px) {
        top: 50px;
    }
        @media all and (max-width: 768px) {
            display: none;
        }
    
    }
    &:nth-child(2)::after{
        content: "";
        width: 65%;
        position: absolute;
        border-top: 2px dashed #1F9BA5;;
        top: 75px;
        right: -20%;
        z-index: -1;
        @media all and (max-width: 1080px) {
        top: 50px;
    }
        @media all and (max-width: 768px) {
            display: none;
        }
    } */
`;
const Content = styled.div`
    /* width: 80%; */
    margin: 0 auto;
    font-family: gordita_regular;
    font-size: 18px;
    @media all and (max-width: 980px) {
        width: 90%;
    }
    @media all and (max-width: 768px) {
        width: unset;
        font-size: 18px;
    }
    @media all and (max-width: 480px) {
        width: 90%;
        font-size: 16px;
    }
    & a {
        color: #0fa76f;
        font-family: gordita_medium;
    }
`;
const SubscribeBtn = styled(Link)`
    cursor: pointer;
    display: block;
    margin: 0 auto;
    color: #fff;
    font-size: 18px;
    font-family: "gordita_medium";
    width: 200px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    transition: 0.5s;
    background-size: 200% auto;
    background-image: linear-gradient(
        to right,
        #63bb4c 0%,
        #1898af 51%,
        #63bb4c 100%
    );
    @media (max-width: 640px) {
        font-size: 15px;
        height: 50px;
    }
    &:hover {
        background-position: right center;
    }
`;
