import React, { useContext, useEffect, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Context } from "../../context/store";
import TefunRouter from "../routers/app/TefunRouter";
import { yiaaiConfig } from "../../helpers/axiosConfig";
import RegisterForm from "../../web/screens/RegisterForm";
import TefunCertificate from "../../tefun/TefunCertificate";
// import PrintCertificate from "../../tefun/PrintCertificate";

const TermsAndConditions = lazy(() =>
    import("../../../general/TermsAndConditions")
);
const PrivacyPolicy = lazy(() => import("../../../general/PrivacyPolicy"));
const PageLoader = lazy(() => import("../../includes/PageLoader"));
const LandingPage = lazy(() => import("../../web/screens/LandingPage"));

function AppRouter() {
    const { state, dispatch } = useContext(Context);
    const { is_verified, access_token } = state.user_data;
    const { isLoading, examCompleted } = state.tefunData;
    useEffect(() => {
        if (access_token) tefunData();
    }, [access_token, examCompleted]);

    const tefunData = () => {
        dispatch({
            type: "UPDATE_TEFUN_DATA",
            payload: {
                isLoading: true,
            },
        });
        yiaaiConfig
            .get("free-courses/check-free-course-status/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    // setLoading(false);
                    dispatch({
                        type: "UPDATE_TEFUN_DATA",
                        payload: {
                            isLoading: false,
                            examStarted: data.is_exam_started,
                            examCompleted: data.is_exam_completed,
                            courseCompleted: data.is_completed,
                            examScheduled: data.is_exam_scheduled,
                            isStarted: data.is_started,
                            currentTopic: data.current_topic,
                            examScheduledStart: data.from_date,
                            examScheduledEnd: data.to_date,
                            isStudentExists: true,
                            certificateId: data.certificate_id,
                        },
                    });
                    // console.log("inside api fetch");
                    // console.log(
                    // 	state.tefunData,
                    // 	"heyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy"
                    // );
                } else {
                    // setLoading(false);
                    dispatch({
                        type: "UPDATE_TEFUN_DATA",
                        payload: {
                            isLoading: false,
                            isStudentExists: data.is_student_exists,
                            isStarted: data.is_started,
                        },
                    });
                }
            })
            .catch((error) => {
                // setLoading(false);

                console.log(error);
            });
    };

    return !isLoading ? (
        <>
            <Routes>
                <Route>
                    {/* <Route path="/*" element={<Navigate to="/tefun" />} /> */}
                    {is_verified ? (
                        <Route path="tefun/*" element={<TefunRouter />} />
                    ) : (
                        <Route path="/" element={<LandingPage />} />
                    )}

                    <Route path="tos" element={<TermsAndConditions />} />
                    <Route path="privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="register-school" element={<RegisterForm />} />
                    <Route
                        path="tefun-program/certificate"
                        element={
                            // <PrivateRoute>
                            <TefunCertificate />
                            // </PrivateRoute>
                        }
                    />
                    {/* {examCompleted && (
						<Route
							path="print-certificate"
							element={
								// <PrivateRoute>
								<PrintCertificate />
								// </PrivateRoute>
							}
						/>
					)} */}
                    <Route path="landing-page" element={<LandingPage />} />
                </Route>
            </Routes>
        </>
    ) : (
        <PageLoader />
    );
}

export default AppRouter;
