import React from "react";
import { Helmet } from "react-helmet";

const TalropEdtechHelmet = ({ title }) => {
    if (title) {
        return (
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Yiaai | Unlock Free Tech Education with Talrop's
                    OTT Platform
                </title>
                <meta
                    id="og-title"
                    property="og:title"
                    content={`${title} | Steyp`}
                />
            </Helmet>
        );
    } else {
        return (
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Yiaai | Unlock Free Tech Education with Talrop's
                    OTT Platform
                </title>
                <meta id="og-title" property="og:title" content="Yia.ai" />
            </Helmet>
        );
    }
};

export default TalropEdtechHelmet;
