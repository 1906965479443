import React, { useState, useContext, useRef } from 'react'
import styled from 'styled-components'
import { Link, useSearchParams } from 'react-router-dom'
import { Context } from '../../../context/store'
import TermsService from '../../../../general/login/TermsServices'
import { accountsConfig } from '../../../helpers/axiosConfig'
import TefunLoader from '../../../tefun/TefunLoader'
import OtpCard from './general/OtpCard'
import ReCAPTCHA from 'react-google-recaptcha'

function EnterOTPModal() {
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    state: {
      user_validate: { selected_country, phone },
    },
  } = useContext(Context)
  const recaptchaRef = useRef(null)
  const [error, setError] = useState(false)
  const [error_message, setErrorMessage] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [isResendLoading, setResendLoading] = useState(false)
  const [isResendSuccess, setResendSuccess] = useState(null)
  const [otp, setOtp] = useState('')

  //Entering otp values will read in the setstate, after it occupies 4 value it will call submit function
  const onChange = (e) => {
    const length = e.target.value.toString().length
    const otp_value = e.target.value
    if (length <= 4) {
      setError(false)
      setOtp(otp_value)
      if (length === 4) {
        onSubmit(null, otp_value)
      }
    }
  }

  //Preventing "Enter" key function while entering any keys
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      onSubmit()
    } else if (e.keyCode === 69) {
      e.preventDefault()
    }
  }

  const onSubmit = (e, otp_value) => {
    if (e) {
      e.preventDefault()
    }
    const otpNumber = otp_value ? otp_value : otp
    if (otpNumber) {
      setLoading(true)
      accountsConfig
        .post('/api/v1/accounts/signup/verify/phone/', {
          otp: otpNumber,
          country: selected_country.web_code,
          phone: phone,
        })

        .then((response) => {
          const {
            StatusCode,
            data: { message },
          } = response.data
          setLoading(false)

          if (StatusCode === 6000) {
            setSearchParams({ action: 'name' })

            setLoading(false)
          } else if (StatusCode === 6001) {
            setError(true)
            setErrorMessage(message)
            setLoading(false)
          }
        })
        .catch((error) => {
          setError(true)
          setErrorMessage('An error occurred, please try again later')
          setLoading(false)
        })
    } else {
      setError(true)
      setErrorMessage('This field cannot be left blank')
    }
  }

  const onResend = async () => {
    setResendLoading(true)
    const token = await recaptchaRef.current.executeAsync()
    accountsConfig
      .post('/api/v1/accounts/signup/resend/otp/', {
        service: 'learn',
        country: selected_country.web_code,
        phone: phone,
        'g-recaptcha-response': token,
      })

      .then((response) => {
        const { StatusCode, message } = response.data
        if (StatusCode === 6000) {
          setResendLoading(false)
          setResendSuccess(true)
        } else if (StatusCode === 6001) {
          setResendLoading(false)
          setResendSuccess(false)
        }
      })
      .catch((error) => {
        setResendLoading(false)
        setResendSuccess(false)
      })
  }

  return (
    <Container className="container">
      <JoinNow>
        {/* <CloseIcon
          title="Close"
          className="las la-times-circle"
          onClick={props.closeModal}
        ></CloseIcon> */}
        <ItemContainer>
          <Content>
            <Title className="g-medium">
              A One Time Password has been sent!
            </Title>
            <Description className="g-medium">
              Please enter the four-digit number which has been sent to the
              registered mobile number.
            </Description>

            <OtpCard
              error={error}
              error_message={error_message}
              handleKeyDown={handleKeyDown}
              onChange={onChange}
              otp={otp}
              onResend={onResend}
              isResendSuccess={isResendSuccess}
              isResendLoading={isResendLoading}
            />

            <Desclimer className="g-medium">
              If you are facing any issues to get the OTP, Kindly Whatsapp to{' '}
              <a
                href="https://wa.me/+918589998713"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: ' #5cc66a', fontSize: '13px' }}
                className="g-medium"
              >
                +91 858 999 8713
              </a>
            </Desclimer>
            <ReCAPTCHA
              ref={recaptchaRef}
              //This ref can be used to call captcha related functions in case you need.
              sitekey="6Ld3vf0hAAAAAD1Wh6Zhx2xYbrgHt4sjvoiSc4pq"
              size="invisible"
              badge="bottomleft"
            />
            <BottomButton
              onClick={() => onSubmit()}
              className="b-medium white"
              to="/"
            >
              {isLoading ? <TefunLoader /> : 'Verify'}
            </BottomButton>
          </Content>

          <TermsService />
        </ItemContainer>
      </JoinNow>
    </Container>
  )
}

export default EnterOTPModal

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 500;
`
const CloseIcon = styled.span`
  font-size: 34px;
  color: #ff9800;
  position: absolute;
  left: -42px;
  top: 11px;
  cursor: pointer;
  @media (max-width: 480px) {
    font-size: 30px;
    left: -38px;
  }
  @media (max-width: 400px) {
    left: 9px;
    top: 11px;
    z-index: 500;
  }
`
const JoinNow = styled.div`
  z-index: 500;
  background: #fff;
  width: 600px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  animation: slide-box 0.4s ease;
  @keyframes slide-box {
    0% {
      right: -600px;
    }
    100% {
      right: 0;
    }
  }
  @media (max-width: 640px) {
    width: 428px;
  }
  @media (max-width: 480px) {
    width: 359px;
  }
  @media (max-width: 400px) {
    width: 100%;
  }
`
const ItemContainer = styled.div`
  background: url('https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/auth/decorator.svg');
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 980px) {
    background-position-y: -106px;
  }
  @media (max-width: 640px) {
    padding: 0 29px;
    background-position-y: -79px;
  }
`
const Content = styled.div`
  padding-top: 101px;
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 640px) {
    padding-top: 52px;
  }
`
const Title = styled.h4`
  font-size: 25px;
  line-height: 1.4em;
  margin-top: 14px;
  @media (max-width: 640px) {
    font-size: 23px;
    margin-top: 0;
  }
  @media (max-width: 480px) {
    font-size: 20px;
  }
`
const Description = styled.p`
  margin: 18px 0 40px;
  width: 94%;
  font-size: 14px;
  @media (max-width: 480px) {
    width: 100%;
    font-size: 13px;
  }
`
const Desclimer = styled.span`
  color: #4d4e4e;
  display: block;
  font-size: 13px;
  margin-top: 35px;
`
const BottomButton = styled(Link)`
  background: linear-gradient(
    272deg,
    rgba(34, 193, 195, 1) 0%,
    rgba(99, 187, 76, 1) 0%,
    rgba(24, 152, 175, 1) 100%
  );
  display: block;
  border-radius: 6px;
  min-height: 58px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 30px 0 108px;
  color: #fff;
  min-height: 50px;
  @media (max-width: 640px) {
    margin-bottom: 0;
  }
  @media (max-width: 480px) {
    min-height: 44px;
    font-size: 15px;
  }
`
const Shape = styled.img`
  max-height: 29px;
  max-width: 29px;
  @media (max-width: 640px) {
    display: none;
  }
`
