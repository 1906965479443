import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { yiaaiConfig } from "../../helpers/axiosConfig";
import Timer from "../general/Timer";
import TefunLoader from "../../tefun/TefunLoader";

function OtpModal({
    isOtpModal,
    setIsOtpModal,
    name,
    campus,
    phoneNo,
    districtId,
    city,
    email,
    course,
    loading,
    setLoading,
    setIsModal,
    setCity,
    setName,
    setPhoneNo,
    setEmail,
    setCampus,
    countryCode,
    studentCount,
    classIds,
    classCategoryIds,
    pincode,
    categoryId,
    logo,
}) {
    const [otp, setOtp] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const navigate = useNavigate();
    const [isResendLoading, setResendLoading] = useState(false);
    const [isResendSuccess, setResendSuccess] = useState(null);
    const [counter, setCounter] = useState(5);
    const [isErrorMsg, setIsErrorMsg] = useState(false);

    useEffect(() => {
        setCounter(5);
    }, [isOtpModal]);
    const counterClock = () => {
        if (isOtpModal) {
            counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
        }
    };

    counterClock();
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            submitForm();
        } else if (e.keyCode === 69) {
            e.preventDefault();
        }
    };
    // const onChange = (e) => {
    //     const length = e.target.value.toString().length;
    //     const otp_value = e.target.value;
    //     if (length <= 4) {
    //         setIsErrorMsg(false);
    //         setOtp(otp_value);
    //         if (length === 4) {
    //             onSubmit(null, otp_value);
    //         }
    //     }
    // };

    const handleClear = () => {
        setIsErrorMsg(false);
        setIsOtpModal(false);
        setOtp("");
        setErrorMsg("");
        setResendLoading(false);
        setResendSuccess(null);
        setCounter(30);
    };

    const submitForm = (e) => {
        if (e) {
            e.preventDefault();
        }
        setLoading(true);
        yiaaiConfig
            .post(`campuses/request-for-free-course-association/verify/`, {
                campus_name: campus,
                location: city,
                contact_name: name,
                contact_phone: phoneNo,
                city: city,
                contact_email: email,
                course: course,
                otp: otp,
                country: countryCode,
                pincode: pincode,
                campus_category: categoryId,
                campus_sections: classIds,
                campus_classes: classCategoryIds,
                total_students: studentCount,
                campus_logo: logo,
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setLoading(false);
                    navigate("/tefun");
                    setIsErrorMsg(false);
                    setIsOtpModal(false);
                    setCity("");
                    setName("");
                    setPhoneNo("");
                    setEmail("");
                    setCampus("");
                }
                if (StatusCode === 6001) {
                    setIsErrorMsg(true);
                    setErrorMsg("Invalid OTP");
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
                setResendLoading(false);
            });
    };

    const onResend = (e) => {
        e.preventDefault();
        yiaaiConfig
            .post(`campuses/resend/otp/`, {
                contact_phone: phoneNo,
                country: countryCode,
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setResendSuccess(true);
                    setLoading(false);
                    setIsModal(false);
                    setResendLoading(false);
                    setIsOtpModal(false);
                    setIsErrorMsg(false);
                }
                if (StatusCode === 6001) {
                    setResendLoading(false);
                    setResendSuccess(false);
                    setErrorMsg("Invalid OTP");
                    setIsErrorMsg(true);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    return (
        <BackContainer style={{ transform: isOtpModal && "scale(1,1)" }}>
            <Overlay>
                <Modal>
                    <Cover>
                        <Container>
                            <OtpContainer>
                                <CloseIconContainer onClick={handleClear}>
                                    <CloseIcon
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/close.svg"
                                        alt="Icon"
                                    />
                                </CloseIconContainer>
                                <Heading>Verify the OTP</Heading>
                                <SubHeading>
                                    Enter the four digit OTP sent to the
                                    registered mobile number
                                </SubHeading>
                                <OtpBox>
                                    <Input
                                        max={9999}
                                        maxLength="4"
                                        pattern="\d*"
                                        type="number"
                                        value={otp}
                                        placeholder="Enter your OTP"
                                        onKeyDown={handleKeyDown}
                                        onChange={(e) => setOtp(e.target.value)}
                                    />
                                    {isErrorMsg ? (
                                        <ErrorMsg>{errorMsg}</ErrorMsg>
                                    ) : null}
                                </OtpBox>
                                <Resend>
                                    <Timer
                                        isResendLoading={isResendLoading}
                                        isResendSuccess={isResendSuccess}
                                        onResend={onResend}
                                        counter={counter}
                                        setCounter={setCounter}
                                    />
                                </Resend>
                                <ButtonContainer>
                                    <Button
                                        className="save"
                                        onClick={submitForm}
                                    >
                                        {loading ? <TefunLoader /> : "Submit"}
                                    </Button>
                                </ButtonContainer>
                            </OtpContainer>
                        </Container>
                    </Cover>
                    <LineOne></LineOne>
                    <LineTwo></LineTwo>
                </Modal>
            </Overlay>
        </BackContainer>
    );
}

export default OtpModal;

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
`;
const Overlay = styled.div`
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    height: 100%;
`;
const Modal = styled.div`
    width: 600px;
    max-height: 90vh;
    overflow: hidden;
    background-color: #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 10px;
    transition: 0.5s;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    @media all and (max-width: 980px) {
        width: 75%;
    }
    @media all and (max-width: 640px) {
        width: 90%;
    }
`;
const Cover = styled.div`
    width: 100%;
    padding: 40px;
    @media all and (max-width: 640px) {
        padding: 20px;
    }
`;
const ErrorMsg = styled.span`
    position: absolute;
    left: 0;
    color: #f46565;
    bottom: -35px;
    font-size: 15px;
    @media all and (max-width: 640px) {
        font-size: 13px;
        bottom: -25px;
    }
`;
const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const CloseIconContainer = styled.div`
    position: absolute;
    width: 20px;
    top: 0px;
    right: 0px;
    cursor: pointer;
    z-index: 2000;
    @media all and (max-width: 480px) {
        width: 15px;
    }
`;
const CloseIcon = styled.img`
    width: 100%;
    display: block;
`;
const OtpContainer = styled.div`
    width: 100%;
    @media all and (max-width: 640px) {
        width: 100%;
    }
`;
const Heading = styled.h3`
    font-family: "gordita_regular";
    font-weight: 600;
    font-size: 20px;
    color: #003c3c;
    @media all and (max-width: 640px) {
        font-size: 17px;
    }
`;

const SubHeading = styled.p`
    margin-top: 10px;
    font-family: "gordita_regular";
    font-weight: 400;
    font-size: 14px;
    color: #5a5a5a;
`;
const Span = styled.span`
    color: #0fa76f;
`;
const OtpBox = styled.div`
    position: relative;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    grid-gap: 10px;
`;
const Input = styled.input`
    width: 100%;
    letter-spacing: 3px;
    margin: 0 auto;
    font-size: 18px;
    font-family: gordita_medium;
    color: #000;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #000;
    outline: none;
    box-sizing: border-box;
    border-radius: 5px;
    &::placeholder {
        font-size: 14px;
    }
`;
const Resend = styled(SubHeading)`
    margin: 5px 0 40px;
    text-align: right;
    max-width: unset;
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
const Button = styled.span`
    width: 150px;
    height: 40px;
    display: flex;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    font-family: gordita_medium;
    background-image: linear-gradient(to right, #63bb4c 0%, #1898af 100%);
    filter: ${(props) => (props.type === "notvalid" ? "grayscale(1)" : "")};
    cursor: ${(props) =>
        props.type === "notvalid" ? "not-allowed" : "pointer"};
    color: #0fa76f;
    border-radius: 5px;
    &:hover {
        opacity: 0.8;
    }
    &.save {
        color: #fff;
        background-color: #0fa76f;
    }
    @media all and (max-width: 480px) {
        margin: 0 auto;
    }
`;
const LineOne = styled.div`
    width: 100%;
    height: 5px;
    background: #6ec8aa;
`;
const LineTwo = styled.div`
    width: 100%;
    height: 5px;
    background: #66b57c;
`;
